
.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 50px 0;
    margin: 0;
    min-height: 600px;


    @media (max-width: 1400px) {
        min-height: 70vh;
    }

    @media (max-width: 576px) {
        padding: 0;
    }
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
    background-color: #4d293b;
}

.banner-content {
    &.landing{
        position: relative;
        z-index: 1;
        p{
            color: var(--color-white) !important;
            font-weight: 500;
        }
        a{
            @media (max-width: 768px) {
                width: 100% !important;
            }
        }
    }
}


.banner-search-icon{
    color: var(--color-white);
    transition: 0.3s;
    &:hover{
        color: var(--color-primary);
    }
}

.searchbox-container{
    @include flex();
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 0 20px;
    }

    &::selection{
        input{
            background-color: aqua !important;
            animation: none !important;
            transform: scale(1) !important;
        }
    }

    input{
        height: 50px !important;
        border-radius: 5px !important;
        margin-right: 12px;
        animation: searchGrowShrink 2s infinite;
        // animation-delay: 1s;
        transition: 0.3s;

        @media (max-width: 576px) {
            min-width: 100% !important;
            margin-right: 0px;
            margin: 10px 0;
        }

        &.input1 {
            animation-delay: 2s !important;

            &.hasInput{
                animation: none !important;
                transform: scale(1.02) !important;
            }
        }

        &.input2{
            animation-delay: 2.15s !important;
        }
        &:focus{
            transition: 0.3s;
            animation: none !important;
            transform: scale(1.02) !important;
            box-shadow: 0px 10px 50px 0px rgba(255, 61, 71, 0.479),
            0px 2px 15px 1px rgba(255, 61, 71, 0.479) !important;
        }
    }

    button{
        margin: 15px;
        align-self: flex-end;
    }
}

.landing-hero-socials{
    @include flex(row, flex-start, center);
    width: 100%;

    @media (max-width: 768px) {
        justify-content: center;
    }

    a{
        margin: 10px 30px 10px 0;
        transition: 0.3s;
        @media (max-width: 768px) {
            margin: 10px 0;
        }
        i{
            transition: 0.3s;
            font-size: 2.5rem;
            color: #fff;
            &:hover{
                transition: 0.3s;
                color: var(--color-primary);
            }
        }
    }
}

@keyframes searchGrowShrink {
    0% {
        transform: scale(1);
        box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
        0px 2px 15px 1px rgba(26,46,85,0.05);
    }
    20% {
        transform: scale(1.02);
        box-shadow: 0px 10px 50px 0px rgba(255, 61, 71, 0.479),
        0px 2px 15px 1px rgba(255, 61, 71, 0.479)
    }
    40% {
        transform: scale(1);
        box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
        0px 2px 15px 1px rgba(26,46,85,0.05);
    }
}

.typing-text-container {    @include flex();
    h2{
        margin: 0;
        min-height: 120px;

        @media (max-width: 576px) {
            min-height: 150px;
        }

        @media (max-width: 350px) {
            min-height: 200px;
        }
    }

    img{
        @media (min-width: 576px) {
            border-bottom-left-radius: 80px;
        }
    }
    span{
        font-weight: 200;
        color: var(--color-secondary);
    }
}

.hero-counter-area{
    padding-top: 30px;
    padding-bottom: 0px;
    margin-bottom: 50px;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
    0px 2px 15px 1px rgba(26,46,85,0.05);

    .counter1, p{
        animation: counterShine 2s infinite;
        animation-delay: 3s !important;
        color: var(--color-secondary);
    }

    .counter2, p{
        animation: counterShine 2s infinite;
        animation-delay: 2.75s !important;
        color: var(--color-secondary);
    }

    .counter3, p{
        animation: counterShine 2s infinite;
        animation-delay: 2.5s !important;
        color: var(--color-secondary);
    }

    .count-number{
        @media (max-width: 576px) {
            font-size: 2.5rem;
            margin-bottom: 0;
        }
    }
}

@keyframes counterShine {
    0% {
        // transform: scale(1);
        color: var(--color-secondary);
    }
    40% {
        // transform: scale(1.02);
        color: var(--color-primary);
    }
    80% {
        // transform: scale(1);
        color: var(--color-secondary);
    }
}

.apply-steps-title{
    margin: 0;
    padding: 0;
}
.apply-steps-section{
    @include flex();
    position: relative;

    @media (max-width: 1400px) {
        transform: scale(0.7);
        margin: -80px 0;
    }

    @media (max-width: 991.98px) {
        transform: scale(0.6);
        margin: -100px 0 -80px 0;
    }

    @media (max-width: 850px) {
        transform: scale(0.5);
        margin: -150px 0 -120px 0;
    }

    @media (max-width: 768px) {
        transform: scale(0.4);
        margin: -180px 0 -160px 0;
    }

    @media (max-width: 500px) {
        transform: scale(0.3);
        margin: -200px 0 -200px 0;
    }

    --optimization-threshold: 0px;

    .phone-container{
        position: relative;
        z-index: 1;
        transition: 0.7s;
        --left: 0px;
        margin: 0 25px;
        --phone-screen: url('../images/views/landingView/phoneStep01.png');

        @media (max-width: var(--optimization-threshold)) {
            transition: 0.3s;
        }

        .phone{
            background:
                    url('../images/views/landingView/phoneFrame.png'),
                    url('../images/views/landingView/phoneStep01.png') calc(var(--left) + 280px) 0,
                    url('../images/views/landingView/phoneStep02.png') calc(var(--left) + 560px) 0,
                    url('../images/views/landingView/phoneStep03.png') calc(var(--left) + 840px) 0,
                    url('../images/views/landingView/phoneStep04.png') calc(var(--left) + 1120px) 0,
                    url('../images/views/landingView/phoneStep05.png') calc(var(--left) + 1400px) 0,
                    var(--phoneProgressBar),
                    url('../images/views/landingView/phoneBG.png');
            background-size: auto 100%;
            background-repeat: no-repeat;
            width: 280px;
            aspect-ratio: 658/1352;
            transition: 0.7s;
            mask-image: url('../images/views/landingView/phoneMask.png');
            mask-size: auto 100%;

            @media (max-width: var(--optimization-threshold)) {
                background:
                        url('../images/views/landingView/phoneFrame.png'),
                        var(--phone-screen),
                        var(--phoneProgressBar),
                        url('../images/views/landingView/phoneBG.png');
                mask-image: url('../images/views/landingView/phoneMask.png');
                background-size: auto 100%;
                background-repeat: no-repeat;
                width: 280px;
                aspect-ratio: 658/1352;
                transition: 0.3s;
                mask-size: auto 100%;
            }
        }
    }

    .laptop-container{
        position: relative;
        z-index: 2;
        transition: 0.7s;
        --left: 0px;
        margin-left: 25px;
        --laptop-screen: url('../images/views/landingView/laptopStep01.png');

        @media (max-width: var(--optimization-threshold)) {
            transition: 0.3s;
        }

        .laptop{
            background:
                    url('../images/views/landingView/laptopBody.png'),
                    url('../images/views/landingView/laptopStep01.png') calc(var(--left) + 840px) 0,
                    url('../images/views/landingView/laptopStep02.png') calc(var(--left) + 1680px) 0,
                    url('../images/views/landingView/laptopStep03.png') calc(var(--left) + 2520px) 0,
                    url('../images/views/landingView/laptopStep04.png') calc(var(--left) + 3360px) 0,
                    url('../images/views/landingView/laptopStep05.png') calc(var(--left) + 4200px) 0,
                    url('../images/views/landingView/laptopBG.png');
            background-repeat: no-repeat;
            background-size: auto 100%;
            width: 840px;
            aspect-ratio: 1975/1345;
            transition: 0.7s;
            mask-image: url('../images/views/landingView/laptopMask.png');
            mask-size: auto 100%;

            @media (max-width: var(--optimization-threshold)) {
                background:
                        url('../images/views/landingView/XS/laptopBody.png'),
                        var(--laptop-screen),
                        url('../images/views/landingView/XS/laptopBG.png');
                background-repeat: no-repeat;
                background-size: auto 100%;
                width: 840px;
                aspect-ratio: 1975/1345;
                transition: 0.3s;
                mask-image: url('../images/views/landingView/XS/laptopMask.png');
                mask-size: auto 100%;
            }
        }
    }
}

.step-control-btn{
    @include flex();
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0px 10px 50px 0px rgba(230, 0, 31, 0.2),
    0px 2px 15px 1px rgba(129, 5, 5, 0.2);
    object-fit: contain;
    transition: 0.3s;

    &.desktop{
        @media (max-width: 1200px) {
            display: none;
        }
    }

    &.disabled{
        opacity: 0.5;
        &:hover{
            transform: scale(1);
        }
    }

    &:hover{
        transform: scale(1.1);
    }
    &:active{
        transform: scale(1);
    }
}

.mobile-controls{
    @include flex();
    margin-top: 10px;
    justify-content: flex-end;
    width: 70vw;

    @media (min-width: 1200px) {
        display: none;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    img{
        margin: 10px;
    }
}



.landing-clouds-main{
    background-image: url('../images/backgrounds/bg1b.jpg');
    background-size: 100% 100%;
    background-position: center;
    padding-top: 60px;
    margin-bottom: 0px;

    @media (max-width: 576px) {
        background-image: url('../images/backgrounds/bg1b-mobile.jpg');
    }
}

.landing-clouds{
    position: absolute;
    transform: translate(0px, -300px);

    @media (max-width: 576px) {
        transform: translate(0px, -500px);
    }
}


.landing-clouds-features{
    margin-top: 250px !important;

    @media (max-width: 1200px) {
        margin-top: 50px !important;
    }

    .landing-clouds-features-link{
        padding: 20px 20px !important;
        &:hover{
            transform: scale(1.05);
        }
    }
    h3{
        font-size: 2.5rem;
        line-height: 1;
        margin-bottom: 5px !important;

        &.h30{
            animation: counterShine 2s infinite;
            animation-delay: 2.5s !important;
            color: var(--color-secondary);
        }
        &.h31{
            animation: counterShine 2s infinite;
            animation-delay: 2.75s !important;
            color: var(--color-secondary);
        }
        &.h32{
            animation: counterShine 2s infinite;
            animation-delay: 3s !important;
            color: var(--color-secondary);
        }
    }

    p{
        line-height: 1;
        margin: 0;
        color: var(--color-secondary);

        &.p0{
            animation: counterShine 2s infinite;
            animation-delay: 2.5s !important;
            color: var(--color-secondary);
        }
        &.p1{
            animation: counterShine 2s infinite;
            animation-delay: 2.75s !important;
            color: var(--color-secondary);
        }
        &.p2{
            animation: counterShine 2s infinite;
            animation-delay: 3s !important;
            color: var(--color-secondary);
        }
    }
    .feature-icon{
        width: 45px;
        height: 45px;
        margin: 0 30px;

        @media (max-width: 400px) {
            margin-left: 5px;
        }

        &.icon0{
            background-image: url('../svgs/landingIcon01black.svg');
            animation: cloudFeaturesShine01 2s infinite;
            animation-delay: 2.5s !important;
        }
        &.icon1{
            background-image: url('../svgs/landingIcon02black.svg');
            animation: cloudFeaturesShine02 2s infinite;
            animation-delay: 2.65s !important;
        }
        &.icon2{
            background-image: url('../svgs/landingIcon03black.svg');
            animation: cloudFeaturesShine03 2s infinite;
            animation-delay: 2.8s !important;
        }
    }
}

@keyframes cloudFeaturesShine01 {
    0% {
        background-image: url('../svgs/landingIcon01black.svg');
    }
    40% {
        background-image: url('../svgs/landingIcon01red.svg');
    }
    80% {
        background-image: url('../svgs/landingIcon01black.svg');
    }
}
@keyframes cloudFeaturesShine02 {
    0% {
        background-image: url('../svgs/landingIcon02black.svg');
    }
    40% {
        background-image: url('../svgs/landingIcon02red.svg');
    }
    80% {
        background-image: url('../svgs/landingIcon02black.svg');
    }
}
@keyframes cloudFeaturesShine03 {
    0% {
        background-image: url('../svgs/landingIcon03black.svg');
    }
    40% {
        background-image: url('../svgs/landingIcon03red.svg');
    }
    80% {
        background-image: url('../svgs/landingIcon03black.svg');
    }
}





.plane-img{
    background-image: url('../images/views/landingView/plane.png');
    width: 100%;
    height: 120px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: planeFly 2s linear infinite;

    @media (min-width: 1200px) {
        display: none;
    }

    @media (max-width: 992px) {
        background-image: url('../images/views/landingView/planeMobile.png');
    }
}

.plane-floatie-animation{
    animation: planeFly2 15s ease-in-out infinite;
}


@keyframes planeFly {
    0%{
        transform: translate(-5px, -5px);
    }
    15%{
        transform: translate(-8px, 0px);
    }
    50%{
        transform: translate(5px, 5px);
    }
    65%{
        transform: translate(8px, 0px);
    }
    100%{
        transform: translate(-5px, -5px);
    }
}

@keyframes planeFly2 {
    0%{
        transform: rotate(1deg);
    }
    15%{
        transform: rotate(3deg);
    }
    50%{
        transform: rotate(-2deg);
    }
    65%{
        transform: rotate(-0deg);
    }
    100%{
        transform: rotate(1deg);
    }
}


.landing-subsection{
    justify-content: flex-start;
    padding: 0;
    margin: 50px 0;

    @media (max-width: 1200px) {
        margin: 30px 0;
    }


    @media (max-width: 991.98px) {
        justify-content: flex-start !important;
        text-align: start;
        align-items: center;
        flex-direction: row;
        padding: 0;
    }

    h2{
        font-size: 2.5rem;
        margin: 0;
        @media (max-width: 991.98px) {
            font-size: 2rem;
        }
    }
    p{
        margin: 0;
        line-height: 1.5;
        @media (max-width: 991.98px) {
            p {
                font-size: 1.8rem;
            }
        }
    }
    img{
        max-width: 40px;
        @media (max-width: 991.98px) {
            margin: 0;
            margin-right: 15px;
            scale: 1;
            max-width: 30px;
        }
    }
}

.lcc-container {
    background:
            url('../images/backgrounds/worldMap.svg') 0 0 / auto 100% repeat-x,
            url('../images/backgrounds/bg1.jpg');
    padding-top: 20px;
    padding-bottom: 80px;
    animation: mapScroll 20s linear infinite;
    width: 100vw;
    height: 100%;
    background-size: contain;

    @media (max-width: 1400px) {
        background-size: cover;
        padding-top: 0;
    }

    @media (max-width: 991.98px) {
        padding-bottom: 20px;
        animation: mapScroll 8s linear infinite;
    }

    @keyframes mapScroll {
        0% {
            background-position: 50vw 0;
        }
        100% {
            background-position: -50vw 0;
        }
    }
}


.landing-country-card{
    @include flex(column, flex-start, flex-start);
    width: 400px;
    border-style: solid;
    border-color: #ff8b91;
    border-radius: 20px;
    border-width: 15px;
    padding: 20px 15px 40px 15px;
    aspect-ratio: 5/7;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
    0px 2px 15px 1px rgba(26,46,85,0.05);
    pointer-events: none;

    @media (min-width: 992px) and (max-width: 1400px) {
        transform: scale(0.8);
    }



    @media (max-width: 440px) {
        width: 90vw;
        border-width: 10px;
    }
}

.lcc-header{
    @include flex(row, flex-start, center);
    margin-bottom: 25px;

    @media (max-width: 440px) {
        margin-bottom: 15px;
    }

    img{
        max-width: 50px;
        margin-right: 10px;
        border-radius: 50px;
    }
    h3{
        margin: 0;
        line-height: 1.1;
        font-size: 2.2rem;
        @media (max-width: 440px) {
            font-size: 2rem;
        }
    }
}

.lcc-thumbnail{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;

    @media (max-width: 440px) {
        max-height: 180px;
    }
}

.lcc-bullet-point{
    @include flex(row, flex-start, center);
    width: 100%;
    margin-top: 25px;

    @media (max-width: 440px) {
        margin-top: 15px;
    }

    img{
        max-width: 25px;
        margin-right: 10px;
    }
    p{
        margin: 0;
        font-size: 1.7rem;
        line-height: 1.2;
    }
}

.landing-career-options-container{
    @include flex(column, center, center);
    padding-top: 60px;
    background-image: url("../images/backgrounds/bg5.jpg");
}

.lco-tiles-container{
    @include flex(row, center, center);
    flex-wrap: wrap;
    width: 100%;
}

.lco-tile {
    // @include flex(column, space-between, center);
    @include flex(column, center, center);
    width: 160px;
    height: 160px;
    aspect-ratio: 1/1;
    background-color: white;
    transition: all 0.3s ease-in-out; /* Apply smooth transition to all properties */
    // padding-top: 30px;
    border-radius: 15px;
    margin: 5px;

    box-shadow: 10px 10px 50px 0px rgba(150, 23, 29, 0.2),
    5px 5px 15px 1px rgba(136, 10, 17, 0.2),
    -10px -10px 50px 0px rgba(255, 255, 255, 0.2),
    -5px -5px 15px 1px rgba(255, 255, 255, 0.2) !important;

    @media (max-width: 400px) {
        transform: scale(0.9) !important;
        margin: -5px;
    }


    img {
        max-width: 50%;
        transition: transform 0.3s ease-in-out; /* Adds smooth transition for scaling */
    }

    p {
        text-align: center;
        line-height: 1.2;
        margin: 0;
        transition: transform 0.3s ease-in-out; /* Adds smooth transition for moving the position */
    }

    h4 {
        @include flex();
        width: 100%;
        text-align: center;
        color: #fff;
        padding: 10px 0;
        font-size: 1.6rem;
        background-color: #ff8b91;
        margin: 0;
        opacity: 0;
        height: auto;
        overflow: hidden; /* Hides content before animation */
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out; /* Smooth transition for opacity and height */
    }

    &:hover {
        // transform: scale(1.05);

        img {
            // transform: translateY(-10px); /* Moves the image up */
        }

        p {
            // color: var(--color-primary);
            // transform: translateY(-10px); /* Moves the paragraph up */
        }

        h4 {
            opacity: 1;
            height: auto; /* Allows the element to expand to its content's height */
        }
    }
}

.lco-bottom{
    @include flex(column, center, center);
    text-align: center;
    margin-top: 30px;

    p{
        color: #fff;
    }
}

.brand-grid-wrap{
    background-color: #ff8b91;
    padding: 0;
    margin: 0;
    object-fit: cover;

    .brand-grid{
        margin: 0;
        padding: 0;
        object-fit: cover;

    }

    img{
        margin: 0;
        padding: 0;
        object-fit: cover;

    }
}

.team-container{
    transform:translate(0px,-120px);
    z-index: 100;
    margin-bottom: -80px;

    @media (max-width: 992px) {
        transform:translate(0px,-90px);
        margin-bottom: -50px;
    }

    h2{
        // font-size: 5rem;
        // line-height: 1.2;
    }
}

.team-text-container{
    @include flex(row, flex-start, center);

    @media (max-width: 992px) {
        justify-content: center;
    }
}

.team-icons-container{
    @include flex(row, flex-end, center);
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    min-height: 300px;

    @media (max-width: 992px) {
        justify-content: center;
    }

    @media (max-width: 512px) {
        min-height: 90vw;
    }

    img{
        margin-right: 4px;
        margin-bottom: 4px;
        width: 150px;
        height: 150px;
        transition: 0.3s;
        object-fit: cover;
        transform: scale(1);
        opacity: 1;
        image-rendering: -webkit-optimize-contrast !important;
        -ms-interpolation-mode: nearest-neighbor;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover{
            transform: scale(0.95);
            opacity: 0.8;
        }

        @media (max-width: 512px) {
            height: 30vw;
            width: 30vw;
        }
    }

    .team-icon-9{
        @media (min-width: 768px) and (max-width: 1200px) {
            display: none;
        }
    }
    .team-icon-10{
        @media (max-width: 1200px) {
            display: none;
        }
    }
}

.contact-us-cta{
    padding: 40px 20px;
    background: url('../images/backgrounds/bg3b.svg') !important;
    background-size: cover !important;
    background-position:center !important;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1), 0px 2px 15px 1px rgba(26,46,85,0.1) !important;

    color: var(--color-white) !important;

    h3,a{
        color: var(--color-white) !important;
        &:hover{
            color: var(--color-primary) !important;
        }
    }

    .cta-get-in-touch{
        @include flex(column, center, flex-end);
        font-family: var(--font-secondary);
        text-shadow: 3px 3px 5px var(--color-secondary-darker);
        height: 100%;

        span{
            font-size: 2.2rem !important;
        }
        h3,a{

            margin: 0;

        }
        @media (max-width: 768px) {
            align-items: center;
            text-shadow: 3px 3px 5px var(--color-secondary-darker), 3px 3px 15px var(--color-secondary-darker);
        }
        h3{
            font-size: 2.4rem !important;
        }
    }

    .cta-whatsapp{
        @include flex(column, center, flex-end);
        font-family: var(--font-secondary);
        height: 100%;
        color: var(--color-white);
        span{
            font-size: 2.2rem !important;
        }

        @media (min-width: 767px) {
            text-shadow: 3px 3px 5px var(--color-secondary-darker);

            h3,a{
                margin: 0;

            }
        }
        @media (max-width: 768px) {
            align-items: center;

            h3{
                font-size: 2.4rem !important;
            }
        }
    }
}

.landing-hero-socials{
    @include flex(row, flex-start, center);
    width: 100%;

    @media (max-width: 768px) {
        justify-content: center;
    }

    a{
        margin: 10px 30px 10px 0;
        transition: 0.3s;
        @media (max-width: 768px) {
            margin: 10px 0;
        }
        i{
        transition: 0.3s;
            font-size: 2.5rem;
            color: #fff;
            &:hover{
                transition: 0.3s;
                color: var(--color-primary);
            }
        }
    }
}