.wave-container {
  position: relative;
  width: 100vw;
  min-height: 300px;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: contain;
  background-image: url('../images/backgrounds/clouds.svg');
  opacity: 1;
  animation: moveWave 30s linear infinite;
  @media (max-width: 991.98px) {
    background-image: url('../images/backgrounds/clouds2.svg');
  }
}

.wave1 {
  opacity: 1;
  animation-duration: 25s;
  z-index: 1;
  scale: 1;
  bottom: -50px;
}

.wave2 {
  opacity: 0.5;
  animation-duration: 30s;
  z-index: 2;
  bottom: -10px;
  animation-delay: -2.5s;
}

.wave3 {
  opacity: 0.3;
  animation-duration: 40s;
  z-index: 3;
  bottom: -20px;
  animation-delay: -12s;
}

@keyframes moveWave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.waveR {
  position: absolute;
  bottom: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: contain;
  background-image: url('../images/backgrounds/clouds.svg');
  opacity: 1;
  animation: moveWaveR 30s linear infinite;
  @media (max-width: 991.98px) {
    background-image: url('../images/backgrounds/clouds2.svg');
  }
}

.wave1R {
  opacity: 1;
  animation-duration: 25s;
  z-index: 1;
  scale: 1;
  bottom: -50px;
}

.wave2R {
  opacity: 0.5;
  animation-duration: 30s;
  z-index: 2;
  bottom: -10px;
  animation-delay: -2.5s;
}

.wave3R {
  opacity: 0.3;
  animation-duration: 40s;
  z-index: 3;
  bottom: -20px;
  animation-delay: -12s;
}

@keyframes moveWaveR {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
