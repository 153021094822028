.App{
  position: relative;
  // top: var(--header-height);
  margin-top: var(--header-height);
  @media (min-width: 576px){
    margin-top: var(--header-height);
  }
}

.header-mainmenu {
  border-bottom: 2px solid var(--color-primary) !important;

}

.mainmenu {
  & li > a.active {
    border-bottom: 4px solid var(--color-primary);
  }
}

.header-btn-custom{
  @include flex(column, center, center);
  font-family: var(--font-secondary);
  font-weight: 500;
  color: var(--color-secondary);
  transition:0.3s;
  margin: 0;

  .btn-circle, &.active .btn-circle{
    color: var(--color-white);
    background-color: var(--color-primary);
    width: 45px;
    height: 45px;
  }

  .btn-hamburger{
    @include flex();
    margin: 0;
    padding: 0;
    transition: 0.5s;
    margin-right: 8px;

    @media (min-width: 1200px) {
      display: none;
    }

    &.hidden{
      transform: scale(0);
      transition: 0.5s;
    }
  }

  .navIconText{
    line-height: 0.5;
    margin: 0;
  }

  &:hover {
    .navIconText{
      transition:0.3s;
      color: var(--color-secondary);
    }
    .btn-circle, &.active .btn-circle{
      background-color: var(--color-secondary);
    }
  }
  &.active {
    .navIconText{
      font-weight: 500;
      color: var(--color-primary);
    }
    &:hover {
      .navIconText{
        transition:0.3s;
        color: var(--color-primary-lighter);
      }
    }
  }

  @media (max-width: 576px) {
    .btn-circle, &.active .btn-circle{
      width: 35px;
      height: 35px;
      padding: 3px !important;
    }
    .navIconText{
      font-size: 1.3rem;
      font-weight: 500;
      transform: translate(0, -1px);
    }
  }
}

.bannerPlaceholder{
  position: absolute;
  height: 95px;
  width: 100%;
  object-fit: cover;
  z-index: 10;
  filter: blur(4px);
  animation: fadeOut 3.5s ease-in 3.5s forwards;
  transform: translate(0px,-85px) scaleX(1.3);

  // @media (max-width: 576px){
  //   height: 75px;
  //   transform: translate(0px,-70px) scaleX(1.3);
  //   filter: blur(2px);
  // }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mobile-nav-button {
  @include flex();
  position: fixed;
  top: 0;
  right: 0;
  width: 60px;
  height: var(--header-height);
  padding: 10px;
  z-index:10000;
  border: none;
  background-color: transparent;
  transition: 0.3s;
  margin-right: 8px;

  &.hidden{
    transition: 0.3s;
    transform: scale(0);
  }

  @media (min-width: 576px) {
    height: 80px;
    width: 80px;
    padding: 20px;
  }
}

