/*-------------------------
    Back To Top  
---------------------------*/
@-webkit-keyframes border-transform {
    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

.paginacontainer {
    height: 3000px;
}

.rn-progress-parent {
    position: fixed;
    right: 30px;
    bottom: 30px;
    @media screen and (max-width: 1200px){
        bottom: 55px;
    }
    height: 60px;
    width: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(255, 71, 87, .2);
    z-index: 998;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;

    &.rn-backto-top-active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }



   /* &::after {
        position: absolute;
        font-family: 'remixicon' !important;
        content: "\ea78";
        text-align: center;
        line-height: 60px;
        font-size: 32px;
        font-weight: 700;
        color: var(--color-secondary);
        left: 0;
        top: 0;
        height: 60px;
        width: 60px;
        cursor: pointer;
        z-index: 2;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;

        @media (max-width: 576px){
            line-height: 50px;
            height: 50px;
            width: 50px;
        }
    }*/

    @media (max-width: 576px){
        right: 12px;
        bottom: 12px;
        height: 50px;
        width: 50px;

    }

    &:hover {
        transform: scale(1.1);
        &::after {
            color: var(--color-secondary);
        }
    }

    &:active{
        transform: scale(1) !important;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
    svg {
        path {
            fill: none;
        }
        &.rn-back-circle {
            path {
                fill: var(--color-tertiary);
                stroke: var(--color-primary);
                stroke-width: 4;
                box-sizing: border-box;
                -webkit-transition: all 200ms linear;
                transition: all 200ms linear;
            }
        }
    }
}