.inner-page-title{
  @include flex();
  padding: 60px 0;
  margin-bottom: -40px;
  line-height: 1;
  background:
          url('../images/backgrounds/clouds.svg') center bottom,
          linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
          linear-gradient(rgba(242,92,91, 0.5), rgba(255, 255, 255, 0.5)),
          url('../images/backgrounds/clouds.svg') center bottom,
          url('../images/backgrounds/bg7.png');
  background-size: auto 40%, 100% 100%, 100% 100%, auto 60%, 100% 100%;
  background-repeat: repeat-x, no-repeat, no-repeat, repeat-x, no-repeat;
}