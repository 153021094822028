.curtain{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0,calc(var(--header-height)*-1));
  animation: raiseCurtain 1s ease forwards;
  animation-delay: 2s;
  z-index: 999;
  /* Stroke animation */
  /* Fill animation using clipPath */

  & svg{
    width: 300px;
    height: 300px;
  }
  //display: none;

  &:not(.raised)~ .App > .edu-header /*> .edu-sticky*/{
    display: none;
  }

  animation: raiseCurtain 1s ease forwards;
  animation-delay: 2s;

  @keyframes raiseCurtain {
    from {
      top: var(--header-height);
      display: flex;
      /* filter: blur(0px); */
    }
    to {
      top: -100%;
      /* top: 150%; */
      display: none;
      /* filter: blur(2px); */
    }
  }

  /* Stroke animation */
  & path {
    fill: none;
    stroke: #ff5a60; /* Stroke color */
    stroke-width: 5;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 2s ease forwards;
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  /* Fill animation using clipPath */
  & .fill-path {
    fill: #ff5a60;
    clip-path: url(#clip-reveal);
    opacity: 0; /* Initially hidden */
    animation: fadeInAndFill 1.5s ease forwards;
    animation-delay: 0.75s; /* Start after stroke animation */
  }

  @keyframes fadeInAndFill {
    0% {
      opacity: 0; /* Initially invisible */
      clip-path: inset(100% 0 0 0); /* Start with clipping */
    }
    50% {
      opacity: 0.8; /* Become visible after stroke animation */
    }
    100% {
      opacity: 1;
      clip-path: inset(0 0 0 0); /* Fully revealed */
    }
  }
}

/* CURTAIN CLOUDS ANIMATION */

.cloudContainer{
  z-index: 1000;
  opacity: 1;
  animation: container-fade 0.5s ease forwards;
  animation-delay: 2.25s;
  position: fixed !important;
  bottom: 0 !important;

  --desktop-initial-scale: 1;
  --desktop-final-scale: 10;
  --mobile-initial-scale: 5;
  --mobile-final-scale: 50;
}

@keyframes container-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.c1{
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  //animation: slide-c1 1.75s ease forwards, grow-c1 2s ease forwards;
  animation-delay: 0s, 2.05s;
  @media (max-width: 1200px){
    //display: none;
    animation: none;
    // transform: translate(100vw, 0) scale(var(--mobile-initial-scale));
    // animation: slide-c1-mobile 1.75s ease forwards, grow-c1-mobile 2s ease forwards;
    // animation-delay: 0s, 2.05s;
  }

}

.c2{
  transform: translate(100vw, 0) scale(var(--desktop-initial-scale));
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.1;
  //animation: slide-c2 1.75s ease forwards, grow-c2 2s ease forwards;
  animation-delay: 0.2s, 2.05s;
  @media (max-width: 1200px){
    //display: none;
    animation: none;
    // transform: translate(100vw, 0) scale(var(--mobile-initial-scale));
    // animation: slide-c2-mobile 1.75s ease forwards, grow-c2-mobile 2s ease forwards;
    // animation-delay: 0s, 2.05s;
  }
}

.c3{
  transform: translate(-100vw, 0) scale(var(--desktop-initial-scale));
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.05;
  //animation: slide-c3 1.75s ease forwards, grow-c3 2s ease forwards;
  animation-delay: 0.3s, 2.05s;
  @media (max-width: 1200px){
    //display: none;
    animation: none;
    // transform: translate(-100vw, 0) scale(var(--mobile-initial-scale));
    // animation: slide-c3-mobile 1.75s ease forwards, grow-c3-mobile 2s ease forwards;
    // animation-delay: 0s, 2.05s;
  }
}

/* --------- ANIMATIONS FOR DESKTOP ----------- */


@keyframes slide-c1 {
  from { transform: translate(-100vw, 0) scale(var(--desktop-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--desktop-initial-scale)); }
}

@keyframes grow-c1 {
  from {
    opacity: 0.2;
    transform: translate(0, 0) scale(var(--desktop-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--desktop-final-scale));
    filter: blur(10px);
  }
}

@keyframes slide-c2 {
  from { transform: translate(100vw, 0) scale(var(--desktop-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--desktop-initial-scale)); }
}

@keyframes grow-c2 {
  from {
    opacity: 0.1;
    transform: translate(0, 0) scale(var(--desktop-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--desktop-final-scale));
    filter: blur(10px);
  }
}

@keyframes slide-c3 {
  from { transform: translate(-100vw, 0) scale(var(--desktop-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--desktop-initial-scale)); }
}

@keyframes grow-c3 {
  from {
    opacity: 0.05;
    transform: translate(0, 0) scale(var(--desktop-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--desktop-final-scale));
    filter: blur(10px);
  }
}

/* --------- ANIMATIONS FOR MOBILE ----------- */

@keyframes slide-c1-mobile {
  from { transform: translate(-300vw, 0) scale(var(--mobile-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--mobile-initial-scale)); }
}

@keyframes grow-c1-mobile {
  from {
    opacity: 0.2;
    transform: translate(0, 0) scale(var(--mobile-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--mobile-final-scale));
    filter: blur(10px);
  }
}

@keyframes slide-c2-mobile {
  from { transform: translate(300vw, 0) scale(var(--mobile-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--mobile-initial-scale)); }
}

@keyframes grow-c2-mobile {
  from {
    opacity: 0.1;
    transform: translate(0, 0) scale(var(--mobile-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--mobile-final-scale));
    filter: blur(10px);
  }
}

@keyframes slide-c3-mobile {
  from { transform: translate(-300vw, 0) scale(var(--mobile-initial-scale)); }
  to { transform: translate(0, 0) scale(var(--mobile-initial-scale)); }
}

@keyframes grow-c3-mobile {
  from {
    opacity: 0.05;
    transform: translate(0, 0) scale(var(--mobile-initial-scale));
    filter: blur(0px);
  }
  to {
    transform: translate(0, 0vw) scale(var(--mobile-final-scale));
    filter: blur(10px);
  }
}









