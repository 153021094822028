// ------------------------ BLOG VIEW STYLING ------------------------

.blogTitle{
  font-family: var(--font-secondary);
  font-weight: 700;
  color: var(--color-secondary);
  text-align: start !important;
  line-height: 1.1;
  font-size: var(--fs-h1-lg);
  letter-spacing: -1px;

  @media (max-width: 991.98px) {
    font-size: var(--fs-h1-sm);
  }
}
.blogAuthor{
  font-size: 1.5rem;
  text-align: start !important;

  a{
    text-transform: uppercase;
  }
}

.blogBanner{
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1),
  0px 2px 15px 1px rgba(26,46,85,0.1);

  @media (max-width: 991.98px) {
    aspect-ratio: 4/3;
    height: auto;
    transform: scale(1.1);
    transform-origin: top center;
    margin: 20px 0 !important;
  }
}

.blogBody{
  font-size: 1.7rem !important;
  line-height: 1.5;
  text-align: start !important;
  margin: 50px 0;
  color: var(--color-secondary);

  h1{
    font-family: var(--font-secondary);
    font-weight: 700;
    line-height: 1.1;
    font-size: var(--fs-h2-sm);
    letter-spacing: -1px;
    padding: 30px;
    background-image: url("../images/backgrounds/bg6.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    text-shadow: 1.5px 1.5px 0px var(--color-secondary);
    margin: 40px 0 20px 0;

    @media (max-width: 991.98px) {
      background-image: url("../images/backgrounds/bg6B.svg");
      font-size: 2.6rem;
      text-align: start;
      padding: 18px;
      transform: scale(1.1);
    }

    @media (max-width: 450px) {
      background-image: url("../images/backgrounds/bg6C.svg");
    }
  }

  h2, h2 a{
    margin: 50px 0 20px 0;
    font-family: var(--font-secondary);
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.1;
    font-size: var(--fs-h2-lg);
    color: var(--color-primary);

    @media (max-width: 991.98px) {
      font-size: var(--fs-h2-sm);
    }

    a{
      color: var(--color-primary-darker);
      &:hover{
        color: var(--color-primar-lighter);
      }
    }
  }

  h3, h3 a{
    margin: 40px 0 15px 0;
    font-family: var(--font-secondary);
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.1;
    font-size: var(--fs-h2-sm);
    color: var(--color-secondary);

    a{
      color: var(--color-primary);
      &:hover{
        color: var(--color-primary-lighter);
      }
    }
  }

  p{
    text-align: start !important;
    padding: 12px 0px;
    font-size: 1.7rem;
    margin: 0;
    line-height: var(--line-height-b1);
    letter-spacing: 0px;


    a{
      font-size: 1.7rem;
      font-weight: 700;
      @media(max-width: 768px) {
        font-size: 1.7rem;
      }
    }
  }

  img{
    width: 100%;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    margin: 15px 0;
    border-radius: 15px;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1),
    0px 2px 15px 1px rgba(26,46,85,0.1);
    @media(max-width: 576px) {
      border-radius: 0px;
      transform: scale(1.1);
    }
  }

  ul li{
    text-align: start !important;
    padding: 0px;
    font-size: 1.8rem;
    color: var(--color-secondary);
    @media(max-width: 768px) {
      font-size: 1.8rem;
    }
    a{
      font-size: 1.8rem;
      font-weight: 700;
      @media(max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    &:before {
      // content: '•';
      position: relative;
      left: -1.5rem;
      color: var(--color-primary);
      font-size: 1.8rem;
      top: 0.1rem;
      font-weight: 800;
    }

    &:has(p) {
      &:before {
        content: '';
      }

      p{
        &:before {
          // content: '•';
          position: relative;
          left: -1.5rem;
          color: var(--color-primary);
          font-size: 1.8rem;
          top: 0.1rem;
          font-weight: 800;
        }
      }

    }
  }

  ol li{
    text-align: start !important;
    padding: 0px;
    font-size: 1.8rem;
    color: var(--color-secondary);
    @media(max-width: 768px) {
      font-size: 1.8rem;
    }
    a{
      font-size: 1.8rem;
      font-weight: 700;
      @media(max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    &:before {
      // content: '✔';
      position: relative;
      left: -1.5rem;
      color: var(--color-primary);
      font-size: 1.8rem;
      top: 0.1rem;
      font-weight: 800;
    }

    &:has(p) {
      &:before {
        content: '';
      }

      p{
        &:before {
          // content: '✔';
          position: relative;
          left: -1.5rem;
          color: var(--color-primary);
          font-size: 2rem;
          top: 0.1rem;
          font-weight: 800;
        }
      }

    }
  }

  table{
    font-size: 1.8rem;
    line-height: 1.2;


    @media (max-width: 991.98px) {
      font-size: 1.7rem;
    }
    @media (max-width: 600px) {
      font-size: 1.4rem;
      line-height: 2.3rem;
    }
    thead tr th{
      padding: 8px;
      background-color: var(--color-primary);
      color: var(--color-white);
      border-style: solid;
      border-width: 1px;
      border-color: var(--color-primary-darker);
    }

    tbody tr td{
      border-color: var(--color-primary);
      padding: 12px;
    }
  }
}

.ctaBanner{
  @include flex(column, center, flex-start);
  position: relative;
  margin: 20px 0 00px 0;
  padding: 20px;
  background-image: url("../images/backgrounds/bg2.jpg");
  background-position: center;
  background: linear-gradient(to bottom, #ffefee, #ffffff);

  @media (max-width: 450px) {
    padding: 12px;
  }

  .ctaBannerContainer{
    @include flex(row, space-between, center);
    width: 100%;
    @media (max-width: 450px) {
      padding: 12px 0;
    }
  }

  .ctaBanner-h1{
    background: none;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.1;
    font-size: 3rem;
    text-shadow: 1.5px 1.5px 0px var(--color-white);
    text-align: start;
    margin: 10px 0;
    padding: 0;
    transform: scale(1);

    @media (max-width: 991.98px) {
      font-size: 2.6rem;
      margin: 10px 0;
      padding: 0;
      transform: scale(1);

    }
  }

  .ctaBanner-p{
    padding: 12px 0px;
    text-align: start !important;
    color: var(--color-secondary);
  }

  .ctaBanner-img{
    border-radius: 0;
    margin: 0 0 0 20px;
    width: 80px;
    max-height: 80px;
    opacity: 0.3;
    box-shadow: none;

    @media (max-width: 450px) {
      display: none;
    }
  }
  .ctaBanner-btn{
    line-height: 1;
    font-weight: 700;
    padding: 10px 0;
  }
}

::marker{
  color: var(--color-primary);
  font-weight: 600;
  font-size: 1.8rem;
}

.blog-card {
  box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
  1px 2px 8px 1px rgba(26,46,85,0.1);
  transition: .2s;
  border-radius: var(--radius);
  border-width: 0;

  .footer-textbox{
    @include flex(row, flex-start, center);
    background-color: #efefef;
    font-family: var(--font-secondary);
    color: var(--color-secondary-lighter);
    font-size: 1.6rem;
    font-weight: 500;
    border-top-width: 0;
    border-bottom-right-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    padding: 0;
    i{
      // font-size: 1.5px;
      margin-left: 20px;
      margin-right: 12px;
      padding: 0;
      color: var(--color-secondary-lighter);

      // max-height: 40px;
    }
  }

  &:hover {
    // border: 1px solid #ffacae;
    // box-shadow: 0px 10px 50px 0px rgb(255, 222, 222),
    // 0px 2px 15px 1px rgb(255, 222, 222);
    color: var(--color-primary);
    cursor: pointer;
    scale: 1.02 !important;

    & .text-muted{
      color: var(--color-primary) !important;
    }
  }

  & > img {
    height: 50%;
    object-fit: cover !important;
    border-radius: var(--radius);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

}

.blogCardSmallContainer{
  @include flex(column, center, center);

  .blogCardSmall{
    @include flex(row, space-between, center);
    width: 100%;
    height: 100px;
    background-color: var(--color-white);
    border-radius: 15px;
    margin: 10px 0;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
    0px 2px 15px 1px rgba(26,46,85,0.05);
    transition: 0.2s;
    color: var(--color-secondary);;

    &:hover{
      color: var(--color-primary);
      // box-shadow: 0px 10px 50px 0px rgb(255, 222, 222),
      // 0px 2px 15px 1px rgb(255, 222, 222);

      @media (min-width: 991.98px) {
        transform: scale(1.02);
      }


    }

    @media (max-width: 400px) {
      margin: 5px 0;
      .blogCardSmallText{
      }
    }

    .blogCardSmallText{
      @include flex(column, center, flex-start);
      // flex-grow: 1;
      padding: 20px;
      min-width: 80%;
      transition: color 0.3s;
      width: 100%;

      @media (max-width: 576px) {
        padding: 15px;

      }

      &:hover{
        h2{
          transition: color 0.3s;
          color: var(--color-primary);
        }
        p{
          transition: color 0.3s;
          color: var(--color-primary);
        }
      }

      h2{
        transition: color 0.3s;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 1.2;
        max-width: 90%;
        margin: 0;
        text-align: start;
        color: var(--color-secondary);

        @media (max-width: 768px) {
          font-size: 1.8rem;
          font-weight: 500;
          letter-spacing: -1px;
        }

        @media (max-width: 576px) {
          // max-width: 100%;
        }

        @media (max-width: 0px) {
          max-width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      p{
        text-align: start;
        transition: color 0.3s;
        width: 90%;
        font-size: 1.5rem;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        margin-top: 10px;
        font-weight: 400;
        color: var(--color-secondary);

        @media (max-width: 768px) {
          font-size: 1.3rem;
        }
        @media (max-width: 576px) {
          max-width: 100%;
        }
        @media (max-width: 400px) {

        }
      }
    }

    img{
      height: 100%;
      min-width: 150px;
      max-width: 150px;
      object-fit: cover;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;

      @media (max-width: 576px) {
        min-width: 100px;
        max-width: 100px;
      }

      @media (max-width: 400px) {
        min-width: 60px;
        max-width: 60px;
      }

    }


  }
}