/*-------------------
  Contact Styles
---------------------*/
.contact-me {
    .inner {
        display: flex;
        align-items: center;
        @media #{$sm-layout} {
            display: block;
            text-align: center;
        }
    }

    .thumbnail {
        margin-right: 130px;
        position: relative;
        z-index: 1;
        @media #{$md-layout} {
            margin-right: 50px;
        }
        @media #{$sm-layout} {
            margin-bottom: 40px;
            margin-right: 0;
        }
        .thumb {
            box-shadow: 0px 20px 40px 0px rgba(42,11,88,0.1);
            border-radius: 50%;
            @media #{$sm-layout} {
                display: inline-block;
            }
            img {
                border-radius: 50%;
            }
        }
        .shape-group {
            @extend %liststyle;
            @media #{$sm-layout} {
                display: none;
            }
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                &.shape-1 {
                    top: -45px;
                    right: 30px;
                }
                &.shape-2 {
                    top: -20px;
                    right: -30px;
                    z-index: 1;
                    @media #{$md-layout} {
                       display: none;
                    }
                }
                &.shape-3 {
                    bottom: -15px;
                    left: -40px;
                }
            }
        }
    }

    .contact-us-info {
        flex: 1;
    }
}

.contact-us-info {
    .heading-title {
        margin-bottom: 26px;
    }
    .address-list {
        @extend %liststyle;
        li {
            .title {
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
    .social-share {
        padding-top: 12px;
    }
}

.contact-me-area {
    .contact-us-info {
        .social-share {
            @media #{$sm-layout} {
               justify-content: center;
            }
        }
    }
}

.contact-form-area {
    background-color: var(--color-lighten01);
    position: relative;
    z-index: 1;
    overflow: hidden;
    .shape-group {
        @extend %liststyle;
        @media only screen and (max-width: 991px) {
           display: none; 
        }
        li {
            position: absolute;
            z-index: -1;
            &.shape-1 {
                top: 32%;
                left: 13%;
            }
            &.shape-2 {
                top: 32%;
                left: 9%;
            }
            &.shape-3 {
                top: -200px;
                right: -110px;
                span {
                    display: block;
                    height: 476px;
                    width: 476px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
            &.shape-4 {
                top: 150px;
                right: 135px;
            }
        }
    }
}

.contact-form {
    &.form-style-2 {
        padding: 70px;
        box-shadow: var(--shadow-darker);
        background-color: var(--color-white);
        border-radius: 5px;
        // margin-bottom: -80px;
        position: relative;
        @media #{$small-mobile} {
            padding: 40px;
        }
        .section-title {
            margin-bottom: 5px;
            p {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
        .form-group {
            margin-bottom: 0;
            input, textarea {
                border-bottom: 1px solid var(--color-border);
                border-radius: 0;
                padding: 20px 0 10px;
            }
            .edu-btn {
                margin-top: 40px;
            }
        }
        .shape-group {
            @extend %liststyle;
            @media only screen and (max-width: 991px) {
               display: none; 
            }
            li {
                position: absolute;
                z-index: -1;
                &.shape-1 {
                    top: -60px;
                    right: -90px;
                }
                &.shape-2 {
                    top: -80px;
                    right: -10px;
                    z-index: 1;
                    
                }
            }
        }
    }
    .error-msg,
    .success-msg {
        p {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.contact-us-area {
    padding: 100px 0 0;
    @media #{$md-layout} {
        padding: 80px 0 0;
    }
    @media #{$sm-layout} {
        padding: 60px 0 0;
    }
}

.google-map-area {
    #gmap_canvas {
        width: 100%;
        height: 600px;
        margin-bottom: -8px;
    }
}
