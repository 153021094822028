.titleParagraphCardRow{
  @include flex();
  flex-grow: 1;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.titleParagraphCardContainer{
  @include flex(row, center, flex-start);
  flex-grow: 1;
  width: 100%;
  margin-top: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.section {
  @include flex(column, center, flex-start);
  padding: 60px 0 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.titleParagraphCard{
    width: 380px !important;
    // min-height: 250px;
    border-radius: 20px;
    box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
    0px 2px 15px 1px rgba(26,46,85,0.05);
    margin: 12px 5px !important;

    @media (max-width: 768px) {
      width: 100% !important;
    }  
  
    h1{
      font-size: 2.2rem;
      font-weight: 500;
      background-color: var(--color-primary);
      padding: 15px;
      text-align: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      color: var(--color-white);
      letter-spacing: 0;
      text-shadow: 3px 3px 5px var(--color-primary-darker);
      margin: 0;

      @media (max-width: 768px) {
        font-size: 1.8rem !important;
        padding: 12px;
      }  
    }

    h3{
      color: var(--color-primary);
      font-size: 2.5rem !important;

      @media (max-width: 768px) {
        font-size: 2rem !important;
      }  
    }
  
    p{
      padding: 10px 25px;
      margin-top: 10px;
      @media (max-width: 768px) {
        padding: 15px 20px;
        margin: 0;
      }  
    }
  
    b{
      color: var(--color-primary)}
    }