.documentChecklistContainer{
    @include flex();
    margin-top: 0px;
    @media (max-width: 991.98px) {
      flex-direction: column;
    } 
  
    .documentChecklistImg{
      max-width: 300px;
      margin: 50px;
      animation: spin 5s ease-in-out infinite;
    }
  
    .documentChecklistForm{
      @include flex(column, space-between, flex-start);
  
      @media (max-width: 991.98px) {
        align-items: center;
      } 
  
      p{
        line-height: 1.5;
        margin-bottom: 30px;
      }
  
      .documentChecklistFormEmail{
        @include flex(column, flex-start, flex-start);
        width: 80%;
        min-height: 250px;
  
        @media (max-width: 991.98px) {
          width: 95%;
        } 
  
        p{
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1;
          margin: 0 0 0 15px;
        }
  
        button{
          @media (max-width: 991.98px) {
            align-self: center;
          } 
        }
  
        &.inputField{
          min-height: 0;
          border-style: hidden;
          margin-bottom: 20px;
          width: 100%;
          font-size: 2rem;
          padding: 12px 25px;
          border-radius: 15px;
          box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
          0px 2px 15px 1px rgba(26,46,85,0.05);

          &:focus{
            box-shadow: 0px 10px 50px 0px rgb(255, 222, 222),
            0px 2px 15px 1px rgb(255, 222, 222);
          }
        }
  
        &.feedback{
          font-size: 2rem;
          width: fit-content;
          min-height: 0;
          margin: 20px 0;
  
          @media (max-width: 991.98px) {
            width: 100%;
            align-items: center;
            justify-content: center;
          } 
        }
      }
    }
  
  
  }
  
  @keyframes spin {
    0% { transform: rotate(-5deg);}
    50% { transform: rotate(5deg); }
    100% { transform: rotate(-5deg); }
  }
  
.inputField::-webkit-validation-bubble-message {
    display: none;
}

.inputField::-webkit-validation-bubble-arrow-clipper,
.inputField::-webkit-validation-bubble-arrow {
    display: none;
}