/*-------------------------
    Variables
-------------------------*/
:root {
    //Themes colors
    --color-none: rgba(0,0,0,0);
    --color-primary: #FF5A60;
    --color-primary-rgb: 255,90,96;
    --color-primary-lighter: #ff999c;
    --color-primary-darker: crimson;
    --color-primary-darkest: darkred;
    --color-secondary: #3B3E55;
    --color-secondary-lighter: #60637e;
    --color-secondary-darker: #292929;
    --color-textSecondary: #ff5b5c;
    --color-textTertiary: #F8F8F8;
    --color-tertiary: #ffff;
    --color-quaternary: rgba(179, 243, 243, 0.1);
    --color-dark: #111212;
    --color-heading: var(--color-secondary);
    --dark-color-heading: #ffffff;
    --color-border: #e5e5e5;
    --dark-color-border: #282f3a;
    --color-body: #808080;
    --gradient-primary: linear-gradient(-90deg, #EE2B47 0%, #FF999C 100%);
    --bg-color: #EAF0F2;
    --dark-bg-color: #020b17;
    --cta-color-bg:#F0F4F5;
    --color-bg-body:#ffffff;
    --dark-color-bg-body:#111822;
    --bg-color-2: #1c242f;
    // Typo Color 
    --color-white: #ffffff;
    --color-black: #000000;
    --footer-text: #181818;
    --dark-footer-text: #808080;
    --edu-btn-color: #ffffff;
    --color-title: #bababa;

    // Lighten Color

    --color-lighten01: #f0f4f5; 
    --color-lighten02: #edf5f8; 
    --color-lighten03: #f5f1eb; 
    --color-lighten04: #f7f5f2; 

    // Extra Color 
    --color-extra01: #FF999C;
    --color-extra02: #8e56ff;
    --color-extra03: #f92596;
    --color-extra04: #5866eb;
    --color-extra05: #f8941f;
    --color-extra06: #39c0fa;
    --color-extra07: #da04f8;
    --color-extra08: #4664e4;
    --color-extra09: #525151;
    --color-extra10: #404040;
    --color-extra11: #22272e;

    // Notify Colors 
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;
    --color-placeholder: #808080;

    //Social icon colors
    --color-facebook: #1877f2;
    --color-twitter: #1da1f2;
    --color-youtube: #cd201f;
    --color-linkedin: #0a66c2;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;
    
    //Border-radius
    --border-width: 2px;
    --radius-small: 4px;
    --radius: 10px;
    --radius-big: 16px;


    //Font weight
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;


    //Shadows
    --shadow-darker: 0px 10px 50px 0px rgba(26,46,85,0.1);
    --shadow-darker2: 0px 20px 50px 0px rgba(26,46,85,0.1);
    --shadow-dark: 0px 10px 30px 0px rgba(20,36,66,0.15);
    --shadow-darkest: 0px 10px 30px 0px rgba(0,0,0,0.05);
    --shadow-darker3:0px 4px 50px 0px rgba(0, 0, 0, 0.1);
    --shadow-darker4: 0px 20px 70px 0px rgba(15, 107, 92, 0.2);

    //transition easing
    --transition: 0.3s;
    --transition-2: 0.5s;
    --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);

    //Font Family
    --font-primary: 'Open Sans', sans-serif;
    --font-secondary: 'Ubuntu', sans-serif;
    --font-icomoon: 'icomoon';

    //Fonts Size
    --font-size-b1: 15px;
    --font-size-b2: 13px;
    --fs-h1-lg: 4rem;
    --fs-h1-sm: 3.5rem;
    --fs-h2-lg: 3.5rem;
    --fs-h2-sm: 3rem;
    --fs-p-lg: 1.7rem;
    --fs-p-sm: 1.5rem;

    //Header
    --header-height: 80px;  
    // @media (max-width: 576px){
    //     --header-height: 60px;
    // }

    //Line Height
    --line-height-b1: 1.73; //26px
    --line-height-b2: 1.85; //24px

    // Heading Font 
    --h1: 50px; //60px 700 1.2
    --h2: 36px; //50px 700 1.39
    --h3: 28px; // 40px 700 1.43
    --h4: 20px; //28px 600  1.4
    --h5: 18px; //26px 600  1.45
    --h6: 16px; //26px 600  1.62


    --h1-lineHeight: 1.2;
    --h2-lineHeight: 1.39;
    --h3-lineHeight: 1.43;
    --h4-lineHeight: 1.4;
    --h5-lineHeight: 1.45;
    --h6-lineHeight: 1.62;

}

// Layouts Variation
$custom-lg-device-seven: 'only screen and (min-width: 1200px)';
$custom-lg-device-three: 'only screen and (min-width: 1750px)';
$custom-lg-device-four: 'only screen and (min-width: 1800px)';
$custom-lg-device-five: 'only screen and (min-width: 1900px)';

$custom-lg-device-two: 'only screen and (min-width: 1401px) and (max-width: 1750px)';
$custom-lg-device-six: 'only screen and (min-width: 1401px) and (max-width: 1650px)';
$custom-lg-device-eight: 'only screen and (min-width: 1200px) and (max-width: 1450px)';

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$custom-laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1400px)';

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';

$lg-layout-1:'only screen and (max-width: 1650px)';
$smlg-device: 'only screen and (max-width: 1199px)';
$md-layout-1:'only screen and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

@mixin flex($direction: row, $justify: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
  }