/*------------------------------
    Popup Menu Styles  
--------------------------------*/

.popup-mobile-menu {
    z-index: 9999;
    content: "";
    width: 90vw;
    @media (min-width: 576px) {
        width: 480px;
    }
    max-height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s ease-out;
    overflow-y: hidden;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .inner {
        z-index: 999;
        position: absolute;
        background-color: #fff;
        height: 100%;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        opacity: 0;
        left: 150px;
        transition: all .5s ease-out;
        overflow-y: hidden;
        .header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            height: var(--header-height);
            background-color: var(--color-primary);
            box-shadow: 0 6px 15px 0 rgba(0, 0, 0, .15);


            @media (min-width: 576px) {
                height: 80px;

            }

            .logo-light {
                display: inline-block;
                transition: opacity 0.2s ease-in-out;
                //width: 50px;
                //height: 50px;
                width: 40px;
                height: 40px;
                background-image: url("../../../brand/logo-symbol-white.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                }

            .close-menu {
                .close-button {
                    background: var(--color-white);
                    border: 0 none;
                    color: var(--color-heading);
                    width: 40px;
                    height: 40px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
                    transition: var(--transition);
                    &:hover {
                        background-color: var(--color-primary);
                        color: var(--color-white);
                    }
                }
            }
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
        .inner {
            opacity: 1;
            left: 0;
            overflow-y: auto;
        }
    }

    .mainmenu {
        overflow-y: hidden;
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 15px 20px;
        li {
            margin: 0;
            a {
                padding: 8px 0;
                display: block;
                font-size: 16px;
                font-weight: 500;
                &.active {
                    color: var(--color-primary) !important;
                }
            }
            & + li {
                border-top: 1px solid var(--color-border);
            }
        }

        .has-droupdown {
            .submenu {
                padding: 0;
                max-width: 100%;
                list-style: none;
                padding-left: 14px;
                display: none;
                li {
                    a {
                        font-size: 15px;
                        &.active {
                            color: var(--color-primary) !important;
                        }
                    }
                }
            }
        }

        .has-droupdown{
            > a {
                position: relative;
                &::after {
                    background-color: var(--color-primary);
                    position: absolute;
                    content: "url('/assets/svgs/iconFAQ.svg')";
                    font-family: 'icomoon' !important;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.4s;
                    font-size: 12px;
                }
                &.open {
                    &::after {
                        content: "\ea0b";
                    }
                }
            }
        }
    }
}

.mm-menu{
    padding: 25px 20px;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			display: block;
            margin: 0;
            margin-top: 5px;

			&:hover{
				> a,button{
					color: var(--color-primary);
                    font-weight: 600;
                    &::after{
                        color: blue;
                        // color: var(--color-body);
                    }
				}
			}
			a,button{
                font-family: var(--font-secondary);
				@include flex(row, space-between, center);
				border-bottom: 1px solid rgba(0, 0, 0, 0.05);
				font-size: 1.8rem;
				color: var(--color-secondary);
				font-weight: 500;
				position: relative;
                width: 100%;
                text-align: left;
                border: none;
                background-color: transparent;
                transition: .3s;
                height: 50px;
                line-height: 1;
			}
            &:not(:last-child) {
                // margin-bottom: 5px;
            }
            & + li {
                border-top: 1px solid var(--color-border);
            }
		}
		
		li.has-droupdown{
			> a,button{
                margin: 0;
                padding: 0;
				&::after{
                    @include flex();
					position: absolute;
                    content: "▼";
                    font-family: 'remixicon'!important;
                    width: 30px;
                    height: 30px;
                    background-color: var(--color-primary-lighter);
                    text-align: center;
                    font-size: 12px;
                    line-height: 30px;
                    display: inline-block;
                    right: 0;
                    // top: 9px;
                    cursor: pointer;
					transition: all 0.3s ease-out;
                    font-weight: 400;
                    border-radius: 50px;
                    color: #fff !important;
				}
			}
            &.active {
                > a,button{
                    &::after{
                        content: "▲";
                        background-color: var(--color-primary);
                    }
                }
            }
			ul.sub-menu{
				list-style: none;
                height: 0;
                margin: 0;
                padding: 0;
                list-style: none;

                &.active{
                    height: 100%;
                }
				li{
                    margin-left: 15px;
                    opacity: 0;
                    
                    &:not(:last-child) {
                        margin-bottom: 0px;
                    }
				}
			}
		}

		li.has-droupdown.active{
			> a{
				color: var(--color-primary);
				&:after{
					-webkit-transform: rotate(-135deg) translateY(-50%);
					transform: rotate(-135deg) translateY(-50%);
				}
			}
			ul.sub-menu.active{
				li{
                    visibility: visible;
                    opacity: 1;
				}
			}
		}

		
	}
}

.body-overlay{
    &.apply{
        position: fixed;
        top: 0;
        right: 0;
        width:100%;
        height:100%;
        background-color: #ffefef;
        z-index: 999;
        transition: 1s;
        opacity: 0.8;
    }
}

[data-theme='dark'] {
    .mm-menu{
        ul{
            li{
                a,
                button {
                    color: var(--color-white);
                    &:hover {
                        color: var(--color-primary);
                    }
                }
                & + li {
                    border-color: var(--dark-color-border);
                }
                &.has-droupdown{
                    > a,button{
                        color: var(--color-white);
                        &::after{
                            background-color: var(--bg-color-2);
                        }
                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
                &:hover {
                    button {
                        &::after{
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}