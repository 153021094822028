/*---------------------------
    Social Share Style
---------------------------*/
.social-share {
    margin: -10px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        margin: 10px;
        a {
            border: 1px solid var(--color-border);
            border-radius: 50%;
            width: 46px;
            height: 46px;
            line-height: 46px;
            display: inline-block;
            font-size: 16px;
            color: var(--color-body);
            transition: 0.3s;
            text-align: center;
            &:hover {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
    &.icon-transparent {
        margin: 0 -15px;
        li {
            margin: 0 15px;
            a {
                height: auto;
                width: auto;
                color: var(--color-body);
                transition: 0.4s;
                line-height: initial;
                font-size: 18px;
                border: none;
                border-radius: 0;
                &:hover {
                    color: var(--color-primary);
                    background-color: transparent;
                }
            }
        }
    }
}


