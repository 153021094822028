/*-------------------
  Hero Banner Styles
---------------------*/
.hero-banner {
    // min-height: 660px;
    display: flex;
    align-items: center;
    padding-top: 20px;

    // @media (max-width: 991.98px) {
    //     text-align: center;
    //     font-size: var(--fs-h1-sm);
    // }    

    .banner-content {
        position: relative;
        z-index: 1;
        @media #{$md-layout} {
           text-align: center;
           margin-bottom: 50px;
        } 
        @media #{$sm-layout} {
           text-align: center;
           margin-bottom: 40px;
        }
        .subtitle {
            font-family: var(--font-secondary);
            font-weight: var(--p-bold);
            color: var(--color-tertiary);
            text-transform: uppercase;
            letter-spacing: 2.6px;
            font-size: 16px;
            margin-bottom: 20px;
            display: block;
        }
        .title {
            margin-bottom: 18px;
            @media #{$smlg-device} {
                br {
                    display: none;
                }   
            }
        }
        p {
            color: var(--color-heading);
        }
        .shape-group {
            @extend %liststyle;
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                &.shape-1 {
                    top: -75px;
                    left: -90px;
                }
            }
        }
    }
    .banner-thumbnail {
        margin-right: -75px;
        position: relative;
        z-index: 1;
        @media #{$smlg-device} {
           margin-right: 0;
        }
        .thumbnail {
            text-align: right;
            @media #{$md-layout} {
                text-align: center;
            }
            @media #{$sm-layout} {
                text-align: center;
            }
        }
        .instructor-info {
            position: absolute;
            bottom: 70px;
            left: 0;
            @media #{$sm-layout} {
                bottom: 40px;
            }
            .inner {
                background-color: var(--color-white);
                border-radius: 10px;
                padding: 26px 30px 16px;
                box-shadow: 0px 20px 40px 0px rgba(0,0,0,.1);
                .title {
                    margin-bottom: 5px;
                }
                .media {
                    display: flex;
                    .thumb {
                        margin-left: -2px;
                    }
                    .content {
                        line-height: 1;
                        color: var(--color-heading);
                        font-weight: 500;
                        margin-top: 10px;
                        span {
                            display: block;
                            font-weight: 700;
                            color: var(--color-secondary);
                        }
                    }
                }
            }
        }
        .shape-group {
            @extend %liststyle;
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                &.shape-1 {
                    top: 50px;
                    left: 160px;
                }
                &.shape-2 {
                    top: 115px;
                    left: 170px;
                }
                &.shape-3 {
                    top: 45%;
                    left: 17%;
                    span {
                        &.circle-shape {
                            width: 41px;
                            height: 41px;
                            background: var(--color-tertiary);
                            border-radius: 100%;
                        }
                    }
                }
                &.shape-4 {
                    top: 114px;
                    right: 41px;
                    z-index: 1;
                }
                &.shape-5 {
                    bottom: 100px;
                    right: -50px;
                }
                &.shape-6 {
                    bottom: 132px;
                    right: -98px;
                }
            }
        }
    }
    
    &.presentation-hero-style {
        min-height: 745px;
        flex-direction: column;
        position: relative;
        justify-content: center;
        z-index: 1;
        clip-path: none;
        min-height: 930px;
        margin-top: -110px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../images/backgrounds/extra/bg-image-27.png);
            z-index: -1;
        }
        @media #{$md-layout} {
           min-height: 740px; 
        }
        @media #{$sm-layout} {
           min-height: 600px; 
        }
        .banner-content {
            text-align: center;
            padding-bottom: 30px;
            @media #{$sm-layout} {
                padding: 160px 0;
            }
            .subtitle {
                letter-spacing: 1px;
                background: #31B978;
                background: linear-gradient(to left, #31B978 0%, #1AB69D 76%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 40px;
                @media #{$sm-layout} {
                    font-size: 14px;
                }
            }
            .title {
                margin-bottom: 40px;
            }
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                transition: var(--transition);
                border-radius: 50%;
                border: 1px solid #dae1e3;
                @media #{$sm-layout} {
                    display: none; 
                }
            }
            &:before {
                height: 570px;
                width: 570px;
                top: -140px;
                left: 200px;
                animation: ripple_3 2.5s linear 1s infinite;
                @media #{$smlg-device} {
                    left: 110px;
                }
                @media #{$md-layout} {
                   height: 400px;
                    width: 400px;
                    left: 154px;
                    top: -70px;
                }
            }
            &:after {
                height: 370px;
                width: 370px;
                top: -43px;
                left: 299px;
                animation: ripple_3 2.5s linear infinite;
                @media #{$smlg-device} {
                    left: 210px;
                }
                @media #{$md-layout} {
                    height: 300px;
                    width: 300px;
                    left: 202px;
                    top: -16px;
                }
            }
            p {
                width: 70%;
                margin: 0 auto 30px;
                @media #{$md-layout-1} {
                    width: 100%;
                }
            }
        }
        .banner-gallery {
            display: block;
            position: initial;
            @media #{$md-layout-1} {
                display: none;
            }
            .thumbnail {
                position: absolute;
                z-index: 2;
                img {
                    @media #{$laptop-device} {
                        width: 75%;
                    }
                    @media #{$smlg-device} {
                        width: 65%;
                    }
                }
                &.thumbnail-1 {
                    top: 225px;
                    left: 0;
                    img {
                        animation: none;
                    }
                }
                &.thumbnail-2 {
                    top: inherit;
                    bottom: 158px;
                    right: inherit;
                    left: 0;
                    padding: 0;
                } 
                &.thumbnail-3 {
                    top: 195px;
                    right: 0;
                    img {
                        border-radius: 0;
                    }
                } 
                &.thumbnail-4 {
                    bottom: 180px;
                    right: 0;
                    @media #{$laptop-device, $smlg-device} {
                        right: -115px;
                    }
                }
                &.thumbnail-5 {
                    top: 135px;
                    left: 325px;
                    border-radius: 10px;
                    img {
                        border-radius: 10px;
                        box-shadow: 0 20px 50px 0 rgba(0,0,0,.04);

                    }
                    @media #{$sm-layout} {
                        display: none;
                    }
                }
            }
        }
        .shape-group {
            @media #{$md-layout-1} {
                display: none; 
            }
            li {
                img {
                    @media #{$laptop-device} {
                        width: 75%;
                    }
                    @media #{$smlg-device} {
                        width: 65%;
                    }
                }
                &.shape-1 {
                    top: auto;
                    bottom: 37%;
                    left: 19%;
                    @media #{$laptop-device} {
                        bottom: 32%;
                        left: 19%;
                    }
                    img {
                        opacity: 1;
                    }
                }
                &.shape-2 {
                    top: auto;
                    bottom: 29%;
                    left: 20%;
                    z-index: 2;
                }
                &.shape-3 {
                    top: 145px;
                    bottom: auto;
                    left: auto;
                    right: 13%;
                } 
                &.shape-4 {
                    top: 29%;
                    bottom: auto;
                    left: auto;
                    right: 15%;
                    z-index: 2;
                    @media #{$laptop-device} {
                        top: 19%;
                        right: 23%;
                    }
                    span {
                        display: block;
                        height: 100px;
                        width: 100px;
                        border: 16px solid var(--color-tertiary);
                        border-radius: 50%;
                        @media #{$laptop-device} {
                            height: 50px;
                            width: 50px;
                            border: 7px solid var(--color-tertiary);
                        }
    
                    }
                }
            }
        }
    }
    &.hero-style-1 {
        align-items: flex-end;
        position: relative;
        background-size: cover;
        background-position: center;
        
        p{
            font-weight: 500;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            

            @media #{$md-layout-1} {  
                display: none;
            }
        }
        @media #{$md-layout} {
            padding-top: 95px;
        }
        @media #{$sm-layout} {
            padding-top: 75px;
        }
        .banner-content {
            .shape-group {
                @media #{$smlg-device} {
                   display: none; 
                }
            }
        }
        .banner-thumbnail {
            max-width: 100%;
            .shape-group {
                @media #{$smlg-device} {
                   display: none; 
                }
                .shape-1 {
                    animation: edublink-sm-x-move 3.5s alternate infinite linear;
                }
            }
        }
        .shape-7 {
            position: absolute;
            right: -6px;
            top: 13%;
            z-index: 1;
            @media #{$smlg-device} {
                display: none;
            }

            img {
                filter: opacity(.7);
                -webkit-filter: opacity(.7);
            }
        }
    }
    &.hero-style-2 {
        padding: 143px 0 180px;
        background-position: center bottom;
        position: relative;

        @media #{$smlg-device} {
            padding: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url(../../../images/backgrounds/extra/bg-image-1.svg);
            filter: brightness(0) saturate(100%) invert(97%) sepia(7%) saturate(503%) hue-rotate(316deg) brightness(103%) contrast(92%);
            background-size: cover;
            background-position: center;
            z-index: -1;
        }
        @media #{$md-layout} {
            padding: 100px 0 150px;
        }
        @media #{$sm-layout} {
            padding: 80px 0 150px;
        }
        .banner-gallery {
            text-align: right;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            position: relative;
            z-index: 1;
            .thumbnail {
                &.thumbnail-1 {
                    img {
                        border-radius: 0 70px 0 110px;
                    }
                }
                &.thumbnail-2 {
                    padding-top: 120px;
                    padding-left: 30px;
                    @media #{$md-layout} {
                        text-align: left;
                    }
                    @media #{$sm-layout} {
                        text-align: left;
                        padding-top: 0;
                    }
                    img {
                        border-radius: 50px 0 90px 0;
                    }
                }
            }
            .online-support {
                position: absolute;
                left: 50px;
                bottom: 40px;
                @media #{$sm-layout} {
                    display: none;
                }
                .inner {
                    display: flex;
                    padding: 30px;
                    align-items: center;
                    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
                    background: #FFFFFF;
                    border-radius: 50px 10px 50px 10px;
                    .icon {
                        width: 50px;
                        height: 50px;
                        background: rgba(238,74,98,0.1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin-right: 15px;
                        color: var(--color-secondary);
                        font-size: 22px;
                    }
                    .content {
                        text-align: left;
                        .subtitle {
                            font-size: 13px;
                            font-weight: var(--p-bold);
                            color: rgba(20,20,23, .5);
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            display: block;
                        }
                        .title {
                            font-weight: var(--p-medium);
                            line-height: 1;
                            margin-bottom: 0;
                            a {
                                color: var(--color-secondary);
                            }
                        }
                    }
                }
            }
            .shape-group {
                @extend %liststyle;
                @media #{$sm-layout} {
                    display: none;
                }
                li {
                    margin: 0;
                    position: absolute;
                    z-index: -1;
                    &.shape-1 {
                        top: -58px;
                        right: 125px;
                        // &.shape-light {
                        //     display: inline-block;
                        // }
            
                        // &.shape-dark {
                        //     display: none;
                        // }
                    }
                    &.shape-2 {
                        top: 85px;
                        right: -50px;
                    }
                    &.shape-3 {
                        bottom: 30px;
                        right: -108px;
                    }
                    &.shape-4 {
                        bottom: -10px;
                        left: -12px;
                    }
                }
            }
        }
    }
    &.hero-style-3 {
        min-height: 715px;
        position: relative;
        z-index: 1;
        mask-image: url(../../../images/masks/mask-01.png);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        @media #{$md-layout} {
            min-height: 100%;
        }
        @media #{$sm-layout} {
            min-height: 100%;
        }
        
        .swiper-slide {
            padding: 0;
            display:block;
            &:before {
                content: "";
                height: 100%;
                width: 100%;
                background: linear-gradient( 90deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 80%);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }
            img {
                transform: scale(1.4);
                transition: transform 5s linear;
                max-width: 100%;
                width: 100%;
                background-size: cover;
                background-position: 100%;
                min-height: 715px;
                object-fit: cover;
            }
            &.swiper-slide-duplicate-active,
            &.swiper-slide.swiper-slide-active {
            img {
                transform: scale(1);
                }
            }
        }
        .thumbnail-bg-content {
            
            .banner-content {
                max-width: 560px;
                position: absolute;
                left: 0;
                bottom: 280px;
                z-index: 12;
                @media #{$custom-lg-device-six} {
                    bottom: 240px;
                }
                @media #{$md-layout-1} {
                    max-width: 100%;
                    bottom: 220px;
                }
                .subtitle {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(50px);
                    transition-delay: 1500ms;
                }
                .title {
                    color: var(--color-white);
                    font-weight: var(--p-extra-bold);
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(60px);
                    transition-delay: 1700ms;
                }
                p {
                    color: var(--color-white);
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(70px);
                    transition-delay: 1900ms;
                }
                .banner-btn {
                    opacity: 0;
                    transform: translateY(80px);
                    transition-delay: 2100ms;
                }
            }
        }
        .swiper-slide-active { 
            .thumbnail-bg-content {
                .banner-content{
                    .subtitle {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0px);
                    }
                    .title {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0px); 
                    }
                    p {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0px);
                    }
                    .banner-btn {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0px);
                    }
                }
            }
        }
        
        .shape-group {
            @extend %liststyle;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
            li {
                margin: 0;
                position: absolute;
                z-index: 1;
                &.shape-1 {
                    top: 45px;
                    left: 8.2%;
                    @media #{$extra-device} {
                        left: 4.2%;
                    }
                    @media #{$lg-layout-1} {
                        display: none;
                    }
                } 
                &.shape-2 {
                    top: 21px;
                    left: 26%;
                }
                &.shape-3 {
                    top: -290px;
                    left: -153px;
                }
            }
        }
        .hero-slider-bg-controls {
            position: absolute;
            right: 70px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            @media #{$md-layout-1} {
                display: none;
            }

            .swiper-slide-controls {
                display: inline-block;
                width: 60px;
                height: 60px;
                line-height: 60px;
                transition: all .4s ease-out;
                background-color: var(--color-white);
                color: var(--color-heading);
                i {
                    font-size: 20px;
                    line-height: 58px; 
                }
                cursor: pointer;

                &:hover {
                    transition: all .4s ease-out;
                    background-color: var(--color-secondary);
                    color: var(--color-white);
                }
            }

            .slide-prev,
            .slide-next {
                position: relative;
                width: 60px!important;
                margin-left: auto;
                margin-right: 6px;
                text-align: center;
                border-radius: 50%;
                box-shadow: var(--shadow-darker3);
            }
            .slide-next {
                right: 0px;
                top: -69px;
            }
            .slide-prev {
                right: -70px;
                top: 0;
            }
        }
    }
    &.hero-style-4 {
        min-height: 745px;
        flex-direction: column;
        position: relative;
        justify-content: center;
        z-index: 1;
        clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 100%);

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../images/backgrounds/extra/bg-image-18.webp);
            z-index: -1;
        }
        @media #{$md-layout} {
            clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 97%);
        }
        @media #{$sm-layout} {
            clip-path: none;
        }
        .banner-content {
            text-align: center;
            padding-bottom: 30px;
            p {
                width: 70%;
                margin: 0 auto 30px;
                @media #{$md-layout-1} {
                    width: 100%;
                }
            }
        }
        .banner-gallery {
            display: block;
            position: initial;
            .thumbnail {
                position: absolute;
                @media #{$laptop-device} {
                    width: 17%;
                }
                @media #{$smlg-device} {
                    width: 15%;
                }
                @media #{$large-mobile} {
                    width: 30%;
                }
                @media #{$md-layout-1} {
                    display: none;
                }
                &.thumbnail-1 {
                    top: 140px;
                    left: 120px;
                    @media #{$laptop-device} {
                        left: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        left: 80px;
                    }
                    
                    img {
                        border-radius: 0;
                        animation: radiusChange 8s linear infinite;
                    }
                }
                &.thumbnail-2 {
                    top: 100px;
                    right: 120px;
                    padding-top: 0;
                    @media #{$laptop-device} {
                        right: 40px;
                    }
                    @media #{$md-layout} {
                        right: 15px;
                    }
                    @media #{$sm-layout} {
                        right: 15px;
                    }
                    img {
                        border-radius: 50px 0px;
                        animation: radiusChange2 5s linear;
                    }
                }
                &.thumbnail-3 {
                    bottom: 160px;
                    right: 310px;
                    padding-top: 0;
                    @media #{$laptop-device} {
                        right: 200px;
                    }
                    @media #{$smlg-device} {
                        right: 100px;
                    }
                    @media #{$md-layout} {
                        right: 15px;
                    }
                    @media #{$sm-layout} {
                        right: 15px;
                    }
                    img {
                        border-radius: 10px 40px;
                    }
                }
            }
            .online-support {
                position: absolute;
                left: 240px;
                bottom: 160px;
                @media #{$smlg-device} {
                   left: 100px;
                   bottom: 100px; 
                }
                @media #{$md-layout-1} {
                    display: none;
                }
                @media #{$sm-layout} {
                    left: 0;
                    bottom: -40px;
                }
                .inner {
                    display: flex;
                    padding: 30px;
                    align-items: center;
                    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
                    background: #FFFFFF;
                    border-radius: 50px 10px 50px 10px;
                    .icon {
                        width: 50px;
                        height: 50px;
                        background: rgba(238,74,98,0.1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin-right: 15px;
                        color: var(--color-secondary);
                        font-size: 22px;
                    }
                    .content {
                        text-align: left;
                        .subtitle {
                            font-size: 13px;
                            font-weight: var(--p-bold);
                            color: rgba(20,20,23, .5);
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            display: block;
                        }
                        .title {
                            color: var(--color-heading);
                            font-weight: var(--p-medium);
                            line-height: 1;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .scroll-down-btn {
            position: absolute;
            bottom: -75px;
            left: 50%;
            transform: translateX(-50%);
             @media #{$sm-layout} {
                bottom: -10px;
                z-index: 12;
             }
            .scroll-btn {
                height: 200px;
                width: 200px;
                background-color: rgba(255,255,255, .5);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                position: relative;
                @media #{$sm-layout} {
                    height: 50px;
                    width: 50px;
                    background-color: rgba(255,255,255, 1);
                    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2);
                    align-items: center;
                }
                i {
                    padding-top: 40px;
                    font-size: 26px;
                    color: var(--color-primary);
                    @media #{$sm-layout} {
                        font-size: 16px;
                        padding-top: 0;
                    }
                }
                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                    border-radius: 50%;
                    border: 1px solid #dfe7e9;
                    @media #{$sm-layout} {
                        display: none; 
                    }
                }
                &:before {
                    animation: ripple_2 3s linear infinite;
                } 
                &:after {
                    animation: ripple_2 3s linear 1s infinite;
                }
            }
        }
        .shape-group {
            @extend %liststyle;
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                @media #{$md-layout} {
                    width: 10%;
                }
                @media #{$sm-layout} {
                    width: 10%;
                }
                &.shape-1 {
                    top: 50px;
                    left: 195px;
                    img {
                        opacity: .5;
                    }
                    @media only screen and (max-width: 1599px) {
                        left: 161px;
                    }
                }
                &.shape-2 {
                    top: 85px;
                    left: 335px;
                    img {
                        width: 130px;
                    }
                    @media only screen and (max-width: 1599px) {
                        left: 215px;
                    }
                }
                &.shape-3 {
                    bottom: 200px;
                    left: 130px;
                }
                &.shape-4 {
                    bottom: 220px;
                    left: 26%;
                }
                &.shape-5 {
                    top: 35px;
                    right: 24%;
                }
                &.shape-6 {
                    top: 66px;
                    right: 88px;
                    @media only screen and (max-width: 1599px) {
                        right: 8px;
                    }
                } 
                &.shape-7 {
                    bottom: 210px;
                    right: 60px;
                    @media only screen and (max-width: 1599px) {
                        bottom: 220px;
                        right: -50px;
                    }
                    img {
                        opacity: .3;
                    }
                }
                &.shape-8 {
                    z-index: -2;
                    bottom: 120px;
                    left: -95px;
                    span {
                        display: block;
                        height: 270px;
                        width: 270px;
                        border: 1px solid var(--color-border);
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    &.hero-style-5 {
        background-color: var(--color-lighten04);
        mask-image: url(../../../images/masks/mask-02.webp);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        min-height: 715px;
        position: relative;
        @media #{$md-layout} {
           padding: 100px 0; 
        }
        @media #{$sm-layout} {
           padding: 80px 0; 
        }
        .banner-thumbnail {
            margin-right: 0;
            text-align: right;
            padding: 40px;
            @media #{$md-layout} {
               text-align: center;
            }
            .thumbnail {
                display: inline-block;
                background-color: var(--color-white);
                border-radius: 10px;
                padding: 20px;
                box-shadow: var(--shadow-darker);
                position: relative;
            }
            .shape-group {
                @media #{$lg-layout} {
                    display: block;
                }
                li {
                    @media #{$md-layout} {
                        width: 100%;
                    } 
                }
                img {
                    transform: none;
                }
                .shape-1 {
                    top: 0;
                    left: auto;
                    right: 0;
                    @media #{$md-layout} {
                       left: 0;
                       right: 0; 
                    }
                } 
                .shape-2 {
                    top: auto;
                    bottom: 85px;
                    left: 52px;
                    z-index: 1;
                }
                .shape-3 {
                    top: 18px;
                    left: auto;
                    right: -45px;
                    z-index: -2;
                }
                .shape-4 {
                    top: -45px;
                    right: 8px;
                }
            }
        }
        .shape-group {
            @extend %liststyle;
            @media #{$sm-layout} {
                display: none;
            }
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                @media #{$md-layout} {
                    width: 15%;
                }
                &.shape-1 {
                    top: 40px;
                    left: 70px;
                } 
                &.shape-2 {
                    bottom: 28px;
                    left: 160px;
                }
                &.shape-3 {
                    top: 85px;
                    left: 46%;
                }
                &.shape-4 {
                    bottom: 70px;
                    right: 205px;
                }
                &.shape-5 {
                    top: 55px;
                    right: 75px;
                }
            }
        }
    } 
    &.hero-style-6 {
        min-height: 710px;
        position: relative;
        z-index: 1;
        @media #{$md-layout} {
            padding: 100px 0 0; 
        }
        @media #{$sm-layout} {
            padding: 80px 0 0; 
        }
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            background-color: var(--color-lighten01);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            mask-image: url(../../../images/masks/mask-03.webp);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            -webkit-mask-position: center;
        }
        
        .banner-thumbnail {
            margin-right: -15px;
            text-align: right;
            margin-bottom: -125px;
            @media #{$md-layout} {
                margin-bottom: 0;
                margin-right: 0;
                text-align: center;
            }
            @media #{$sm-layout} {
                margin-bottom: 0;
                margin-right: 0;
                text-align: center;
            }
            .thumbnail {
                display: inline-block;
                position: relative;
            }
            .shape-group {
                @media #{$smlg-device, $sm-layout} {
                    display: block;   
                }
                img {
                    opacity: 1;                
                }
                .shape-1 {
                    top: 80px;
                    left: auto;
                    right: 15px;
                    @media #{$md-layout-1} {
                        right: inherit;
                        left: 180px;
                    }
                    @media #{$sm-layout} {
                        left: 60px;
                    }
                } 
                .shape-2 {
                    top: auto;
                    bottom: 250px;
                    left: 80px;
                    z-index: 1;
                    @media #{$sm-layout} {
                        display: none;
                    }
                }
                .shape-3 {
                    top: 80px;
                    left: auto;
                    right: -75px;
                    z-index: -2;
                }
                .shape-4 {
                    top: 26px;
                    right: 22px;
                    @media #{$sm-layout} {
                        display: none;
                    }
                }
            }
        }
        .shape-group {
            @extend %liststyle;
            @media #{$sm-layout} {
                display: none;   
            }
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                img {
                    opacity: .2;
                }
                &.shape-1 {
                    top: 45px;
                    left: 250px;
                } 
                &.shape-2 {
                    bottom: 26px;
                    left: 115px;
                }
                &.shape-3 {
                    top: 25px;
                    right: 0px;
                    z-index: -1;
                }
            }
        }
    }
    &.hero-style-7 {
        min-height: 820px;
        flex-direction: column;
        position: relative;
        justify-content: center;
        background: linear-gradient(-90deg, rgba(49,185,120, .2) 0%, rgba(26, 182, 157, .2) 100%);
        overflow: hidden;
        @media #{$md-layout} {
            min-height: 100%;
            padding: 80px 0 100px;
        }
        @media #{$sm-layout} {
            min-height: 100%;
            padding: 60px 0 80px;
        }
        .banner-content {
            text-align: center;
            padding-top: 30px;
            z-index: 2;
            @media #{$md-layout} {
                padding-top: 0;  
            }
            @media #{$sm-layout} {
                padding-top: 0;  
            }
            p {
                width: 70%;
                margin: 0 auto 30px;
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
            .features-list {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 -50px;
                padding-top: 65px;
                @media #{$sm-layout} {
                    margin: 0 -20px;
                    padding-top: 50px;
                }
                @media #{$large-mobile} {
                    margin: 0 -5px;
                }
            }
            .features-box {
                background-color: transparent;
                padding: 0;
                box-shadow: none;
                margin: 0 50px;
                @media #{$sm-layout} {
                    margin: 0 20px;
                }
                @media #{$large-mobile} {
                    margin: 0 5px;
                }
                .icon {
                    color: var(--color-white);
                }
                .content {
                    .title {
                        @media #{$large-mobile} {
                            font-size: 14px;
                        }
                    }
                }
                &.color-extra02-style{
                    .icon { 
                        background-color: rgba(142, 86, 255, 1);
                    }
                }
                &.color-secondary-style {
                    .icon {
                        background-color: rgba(238, 74, 98, 1);
                    }
                }
                &.color-primary-style {
                    .icon {
                        background-color: rgba(26, 182, 157, 1);
                    }
                }
            }
        }
        .banner-gallery {
            display: block;
            position: initial;
            .thumbnail {
                position: absolute;
                @media #{$laptop-device} {
                    width: 20%;
                }
                @media #{$smlg-device} {
                    width: 20%;
                }
                @media #{$sm-layout} {
                    display: none;
                }
                img {
                    border-radius: 0;
                }
                &.thumbnail-1 {
                    bottom: 160px;
                    left: 130px;
                    @media #{$lg-layout-1} {
                        left: 20px;
                    }
                    @media #{$md-layout} {
                        bottom: 50%;
                    }
                    @media #{$smlg-device} {
                        bottom: 50%;
                    }
                }
                &.thumbnail-2 {
                    bottom: 120px;
                    right: 150px;
                    padding-top: 0;
                    @media #{$lg-layout-1} {
                        right: 20px;
                    }
                    @media #{$md-layout} {
                        bottom: 50%;
                    }
                    @media #{$smlg-device} {
                        bottom: 50%;
                    }
                }
            }
        }
        .shape-group {
            @extend %liststyle;
            @media #{$sm-layout} {
                display: none;
            }
            li {
                margin: 0;
                position: absolute;
                z-index: -1;
                img {
                    opacity: .3;
                }
                &.shape-1 {
                    top: 100px;
                    left: 82px;
                }
                &.shape-2 {
                    top: 47%;
                    left: 24%;
                }
                &.shape-3 {
                    bottom: 0;
                    left: 44%;
                }
                &.shape-4 {
                    top: 220px;
                    right: 21%;
                }
                &.shape-5 {
                    top: 135px;
                    right: 65px;
                }
                &.shape-6 {
                    top: -60px;
                    left: 35%;
                    img {
                        opacity: 1;
                    }
                } 
                &.shape-7 {
                    bottom: -35px;
                    right: 21%;
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
    
}