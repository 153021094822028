.testimonialsList{
    margin: 20px 0;
  }
  
  .testimonialsHeader{
      @include flex();
      padding: 20px;
      font-size: 3rem;
      line-height: 1.8;
      border-radius: 30px;
      background-color: var(--color-primary);
      color: #fff !important;
      align-self: center;
      border-top-left-radius: 5px;
    
      @media (max-width: 991.98px) {
        flex-direction: column;
    } 
    
      img{
        max-width: 60px;
        margin: 0 20px;
        animation: spin 5s ease-in-out infinite;
    
        @media (max-width: 991.98px) {
            max-width: 100px;
            margin: 15px 0 0 0;
        } 
    
        @media (max-width: 500px) {
            max-width: 80px;
        } 
      }
    
      p{
        line-height: 1.2;
        margin: 1px;
    
        @media (max-width: 991.98px) {
          margin: 15px;
        } 
      }
  }
  
  .testimonialContainer{
    @include flex(column, center, flex-end);
    margin: 5px 0;
  
    @media (max-width: 991.98px) {
      margin: 30px 0 30px 50px;
    }
  
    &.Left{
      align-items: flex-start;
  
      p{
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 5px;
      }
  
      @media (max-width: 991.98px) {
        margin: 30px 50px 30px 0;
      }
    }
  
    h1{
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 0;
      color: #808080;
      line-height: 1;
      margin: 0 30px;
    }
  
    h2{
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0;
      color: #808080;
      line-height: 1;
      margin: 5px 30px;
    }
  
    p{
      background-color: #ffefee;
      border-radius: 30px;
      // font-size: 1.8rem;
      line-height: 1.4;
      font-weight: 400;
      padding: 20px 40px;
      text-align: start;
      border-bottom-right-radius: 5px;  
      box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
      0px 2px 15px 1px rgba(26,46,85,0.05);
      transition: 0.3s;
      box-shadow: 1.5px 1.5px 2px 0.5px rgba(26,46,85,0.2),
      5px 5px 15px 1px rgba(26,46,85,0.05);

      &:hover{
        scale: 1.05;
        box-shadow: #ffbabd 1.5px 1.5px 2px 0.5px,
        #ffcfcc 5px 5px 15px 1px,;
      }
      margin: 10px 0;
  
      @media (max-width: 991.98px) {
        padding: 20px;
      }
  
      @media (max-width: 700px) {
        font-size: 1.5rem;
      }
    }
  }
  
  .testimonialApostrophe{
    @include flex(row, flex-start, center);
    animation: sidetoside1 1.9s ease-in-out infinite;
  
    img{
      max-width: 120px;
  
      @media (min-width: 991.98px){
        max-width: 150px;
      }
    }
  
    &.Close{
      justify-content: flex-end;
    }
  }
  @keyframes sidetoside1 {
    0% {
        transform: translateX(10px);
    }
    50% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(10px);
    }
}
@keyframes spin {
  0% { transform: rotate(-5deg);}
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
}