.button-custom {
  a, Link, i, img, svg, button{
    all:unset;
    pointer-events: auto;
  }

  @include flex();
  width: fit-content;
  min-height: 50px;
  font-size: var(--fs-p-sm);
  font-weight: 500;
  font-family: var(--font-secondary);
  text-transform: uppercase;
  border-radius: 8px;
  box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
  0px 2px 15px 1px rgba(26,46,85,0.05);
  border-style: solid;
  border-width: 3px;
  transition: 0.5s;
  padding: 0 30px;
  line-height: 1;

  &.circle{
    width: 50px;
    border-radius: 50px;
    padding: 0;
  }

  a{
    line-height: 1;
    padding: 15px 0;
    cursor: pointer;
  }

  &.primary{
    color: var(--color-white);
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &:hover{
      color: var(--color-white);
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
    }

    &.hollow{
      color: var(--color-primary);
      background: rgba(255,255,255, 0.8);

      &:hover{
        color: var(--color-white);
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }

  &.secondary{
    color: var(--color-white);
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);

    &:hover{
      color: var(--color-white);
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }

    &.hollow{
      color: var(--color-secondary);
      background: rgba(255,255,255, 0.8);

      &:hover{
        color: var(--color-white);
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
      }
    }
  }

  &.tertiary{
    color: var(--color-primary);
    background-color: var(--color-white);
    border-color: var(--color-white);

    &:hover{
      color: var(--color-white);
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }

    &.hollow{
      color: var(--color-white);
      background-color: transparent;

      &:hover{
        color: var(--color-primary);
        background-color: var(--color-white);
        border-color: var(--color-white);
      }
    }
  }

  &.disabled{
    color: #606060;
    background-color: #dddddd;
    border-color: #dddddd;
    pointer-events: none;
    cursor: default;
  }




  i,img, svg{
    @include flex();
    max-height: 30px;
    object-fit: contain;
    align-self: center;
    justify-self: center;
    font-size: 2rem;
  }
}





// margin: 0px !important;
// padding: 12px 40px !important;
// background-color: var(--color-primary) !important;
// font-size: 1.8rem !important;
// font-weight: 400 !important;
// letter-spacing: normal !important;
// letter-spacing: 0px !important;
// color: var(--color-white) !important;
// border-radius: 8px;  background-color: var(--color-primary) !important;
// font-family: var(--font-secondary);
// box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1),
// 0px 2px 15px 1px rgba(26,46,85,0.1);
// transition: 0.3s;
// text-transform: uppercase;

// &:hover{
//   background-color: var(--color-primary-lighter) !important;
//   box-shadow: 0px 10px 50px 0px rgba(255, 50, 50, 0.3),
//   0px 2px 15px 1px rgba(255, 65, 65, 0.3);
// }

// &.disabled{
//   background-color: lightgray !important;
//   color: var(--color-secondary) !important;
//   box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1),
//   0px 2px 15px 1px rgba(26,46,85,0.1);

//   &:hover{
//     background-color: lightgray !important;
//     box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.1),
//     0px 2px 15px 1px rgba(26,46,85,0.1);
//   }
// }

.btn-circle {
  /*width: 50px;
  height: 50px;
  border-radius: 150px !important;
  border-width: 0;
  padding: 6px !important;
  //margin-bottom: 5px;*/
  display: inline-flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 2.25em;  /* Size relative to the font size */
  height: 2.25em;
  font-size: 1.5em;
  border: none;
  border-radius: 50%; /* This makes the button a circle */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  @media (min-width: 576px){
    padding: 5px;
  }
}

// ====  Socials =========== //
.btn-facebook:hover {
  background: #155CBA !important;
  color: white;
}

.btn-instagram:hover {
  background: #833AB4 !important;;
  color: white;
}

.btn-tiktok:hover {
  background: #5AB0B5 !important;;
  color: white;
}