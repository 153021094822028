::-moz-selection {
  background-color: var(--color-primary-lighter);
  color: #f0f0f0;
}
::selection {
  background-color: var(--color-primary-lighter);
  color: #f0f0f0;
}

//Custom Scroll-bar
::-webkit-scrollbar {
  width: 1.2rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 1rem;
  border: 1px solid #f0f0f0
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3b3e55;
}


a {
  cursor: pointer;
}


// General Times
$colorTypes: 'primary', 'secondary', 'tertiary', 'quaternary', 'none';

@each $colorType in $colorTypes {
  .text-#{$colorType} {
    color: var(--color-#{$colorType}) !important;
  }
  .text-#{$colorType} {
    color: var(--color-#{$colorType}) !important;

    & > * {
      color: var(--color-#{$colorType}) !important;
    }
  }
  .hover-text-#{$colorType}:hover {
    color: var(--color-#{$colorType}) !important;

    & > * {
      color: var(--color-#{$colorType}) !important;
    }
  }
  .text-shadow-#{$colorType} {
    text-shadow: 1px 1px 2px var(--color-#{$colorType});
  }
  .box-shadow-#{$colorType} {
    box-shadow: inset 1px 1px 2px var(--color-#{$colorType});
  }

  .btn-#{$colorType} {
    background: var(--color-#{$colorType}) !important;
    border-color: lightgray;
  }

  .bg-#{$colorType} {
    background: var(--color-#{$colorType}) !important;
  }
  .hover-bg-#{$colorType}:hover {
    background: var(--color-#{$colorType}) !important;
  }

  .border-#{$colorType} {
    border-color: var(--color-#{$colorType}) !important;
  }
}


.bg-primary {
  a {
    color: var(--color-tertiary) !important;

    &:hover {
      text-shadow: none;
      -webkit-text-fill-color: unset !important;
    }
  }
}


$directions: 'left', 'right';
@each $direction in $directions {
  .border-#{$direction} {
    border-#{$direction}: 1px solid;
  }
}

$breakpoints: '10', '20', '30', '40', '50', '60', '70', '80', '90';

@each $bp in $breakpoints{
  .min-vh-#{$bp}{
    min-height: #{$bp}vh;
  }
  .w-#{$bp} {
    width: #{$bp}#{'%'};
  }

  @media (min-width: 768px) {
    .min-md-vh-#{$bp}{
      min-height: #{$bp}vh !important;
    }
  }
}

.fw-semibold {
  font-weight: 500 !important;
}

.thumbnail {
  border-radius: var(--radius);
  background-position: center;
}

.hover-scale:hover {
  scale: 1.02;
  transition: scale 0.3s ease-in-out !important;
}

img{
  filter: blur(0);
  opacity: 1;
  transition: filter 0.5s ease, opacity 0.5s ease;

  &.loading{
    filter: blur(5px) !important;
    opacity: 0.7 !important;
  }

  &.img-cover{
    object-fit: cover;
    width: 100% !important;
  }

}

.rounded{border-radius: var(--radius) !important;}

.list-style-none{
  list-style: none;
}