.consultantCard{
    @include flex(column, center, flex-start);
    background-color: var(--color-white);
    width: 380px;
    transition: 0.2s;
    height: 600px;
    margin: 30px 10px;
    padding: 80px 40px;
    cursor: grab;
    cursor: -webkit-grab;
    border-style: solid;
    border-color: var(--color-white);
    border-width: 5px;

    @media (min-width: 1200px) and (max-width: 1400px){
      scale:0.8;
    }  
  
    @media (max-width: 991.98px){
      align-items: center;
      padding: 25px;
    }

    @media (min-width: 768px) and (max-width: 991.98px){
      scale:0.9;
    }  

    @media (max-width: 500px) {
      scale:0.9;
    }  

    @media (max-width: 380px) {
      transform: translate(-50px, 0);
      scale:0.8;
    }  
  
    &:hover{

      @media (min-width: 991.98px){
        scale: 1.05;
        z-index: 100;
        border-color: var(--color-primary-lighter);

      }

      @media (min-width: 1200px) and (max-width: 1400px){
        scale:0.85;
      }  
  
      &:active{
        @media (min-width: 991.98px){
          cursor: grabbing;
          cursor: -webkit-grabbing;
        }
      }
    }
  
    img{
      max-width: 300px;
      border-radius: 400px;
      pointer-events: none;
      margin-bottom: 30px;
  
      @media (max-width: 500px) {
        width: 70vw;
      }  
    }
  
    h1{
      font-size: 4rem;
      font-weight: 700;
      letter-spacing:-1px;
      line-height: 1;
  
      @media (max-width: 500px) {
        font-size: 3rem;
      }  
    }
  
    p{
      font-size: 2rem;
      line-height: 1.2;
      font-weight: 400;
      color: var(--color-secondary);
  
      @media (max-width: 500px) {
        font-size: 1.5rem;
      }  
    }
  }
  
  .consultantLinksContainer{
    @include flex(row, flex-start, center);
    width: 300px;
    
    @media (max-width: 991.98px) {
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;
    }  
  
    a{
      width: 50px;
      height: 50px;
      background-color: var(--color-primary);
      border-radius: 300px;
      background-position: center;
      background-size: contain;
      transition: 0.2s;
  
      @media (min-width: 991.98px) {
        margin-right: 25px;
      }  
  
      @media (max-width: 330px) {
        width: 40px;
        height: 40px;
      }  
  
      &.email{
        background-image: url('../svgs/consultantEmail.svg');
      }
  
      &.facebook{
        background-image: url('../svgs/consultantFacebook.svg');
      }
  
      &.instagram{
        background-image: url('../svgs/consultantInstagram.svg');
      }
  
      &.linkedIn{
        background-image: url('../svgs/consultantLinkedIn.svg');
      }
  
      &:hover{
        background-color: var(--color-primary-lighter);
      }
    }
  }