.checklist {
  @include flex();
  padding: 10px;
  font-size: 2.5rem;
  line-height: 1.8;
  margin: 10px 0;

  @media (max-width: 991.98px) {
    margin: 0;
  }

  img {
    max-width: 40px;
    margin-right: 15px;

    @media (max-width: 800px) {
      // max-width: 60px;
    }
  }

  p {
    margin: 5px;
    text-align: start;
  }

  &.white {
    color: var(--color-white);

    i {
      font-weight: 800;
    }
  }
}

.usa_bannerText01 {
  line-height: 2.2rem;

  @media (max-width: 991.98px) {
    line-height: 2rem;
    text-align: start !important;
    padding: 10px;
  }

  b {
    color: var(--color-primary);
  }
}

.usa_icon01 {
  max-height: 50px;
  margin: 12px;

  @media (max-width: 991.98px) {
    margin: 10px;
  }
}

.usa_budgetingSection {
  padding: 0;
}


.usa_budgetingContainer {
  @include flex();
  z-index: 100;

  @media (max-width: 991.98px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .usa_budgetingImg {
    max-width: 400px;
    scale: 1.1;
    transform: translate(0, 50px);
    z-index: 90 !important;

    @media (max-width: 991.98px) {
      transform: translate(0, 10px);
    }

    @media (max-width: 550px) {
      scale: 1;
      max-width: 95vw;
    }
  }

  .usa_budgetingChecklist {
    @include flex(column, center, flex-start);
    @media (min-width: 991.98px) {
      transform: translate(00px, -20px)
    }

  }

  @media (max-width: 991.98px) {
    flex-direction: column;
  }
}

.usa_iconMoneyContainer{
  @include flex();
}

.usa_iconMoney {
  width: 300px;
  animation: moveLeftRight 5s ease-in-out infinite;
}

@keyframes moveLeftRight {
  0% { transform: translateX(-20px); }
  50% { transform: translateX(20px); }
  100% { transform: translateX(-20px); }
}

.canada_tutorialVideo{
  max-height: 80vh;
  width: fit-content;
  background-color: #505153;
  border-style: solid;
  border-radius: 15px;
  border-width: 0;
  object-fit: cover;
  transition: 0.2s;
  box-shadow: 0px 10px 50px 0px rgba(26,46,85,0.05),
  0px 2px 15px 1px rgba(26,46,85,0.05);

  @media (max-width: 510px) {
    border-radius: 0px;
  }

  &:hover{
    transition: 0.2s;
    box-shadow: 0px 10px 50px 0px rgb(255, 222, 222),
    0px 2px 15px 1px rgb(255, 222, 222);
    transform: scale(1.03);
  }
}
