.faqTitleBG{
  padding-top: 66px;
  background-color: var(--color-secondary) !important;
  background-image: url('../svgs/iconFAQ.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  animation: pulse 5s infinite ease-in-out !important;
}

.sfaqTitleBG{
  overflow: hidden !important;
}

@keyframes pulse {
  0% {
    background-size: 80%;
  }
  50% {
    background-size: 70%;
  }
  100% {
    background-size: 80%;
  }
}