/*------------------------
    Header Actions  
-------------------------*/
.header-action {
    @extend %liststyle;
    display: flex;
    align-items: center;
    margin: 0 -20px;
    @media #{$large-mobile} {
        margin: 0 -10px;
    }
    li {
        margin-left: 25px;
        line-height: 1;
        @media #{$large-mobile} {
            margin: 0 10px;
        }
        &.icon {
            a {
                font-size: 22px;
                color: var(--color-heading);
                position: relative;
                height: 90px;
                line-height: 94px;
                .count {
                    position: absolute;
                    top: 24px;
                    right: -10px;
                    display: block;
                    text-align: center;
                    height: 20px;
                    width: 20px;
                    line-height: 23px;
                    background-color: var(--color-primary);
                    border-radius: 50%;
                    color: var(--color-white);
                    font-size: 12px;
                    font-weight: 500;
                    font-family: var(--font-secondary);

                }
                &:hover {
                    color: var(--color-primary);
                }
            }
            &.cart-icon {
                margin-right: 30px;
                @media #{$large-mobile} {
                    margin-right: 20px;
                }
            }
        }
    }
    .search-bar {
        @media only screen and (max-width: 1699px) {
            display: none;
        }
        .input-group {
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            .form-control {
                height: 50px;
                border: none;
                line-height: 1;
                font-size: 15px;
                color: var(--color-heading);
                font-weight: 400;
                padding: 0 0 0 20px;
                &:focus {
                    box-shadow: none;
                }
            }
            .search-btn {
                border: none;
                background-color: transparent;
                font-size: 20px;
                color: var(--color-heading);
                padding: 0 20px;
                transition: var(--transition);
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    .header-btn {
        @media #{$sm-layout} {
            display: none;
        }
        a {
            display: block;
            color: var(--color-white);
            padding: 0 30px;
        }
    }
    .mobile-menu-bar {
        .hamberger-button {
            background-color: transparent;
            border: none;
            padding: 0;
            color: var(--color-heading);
            font-size: 15px;
            line-height: 1;
            transition: var(--transition);
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}